import React from 'react';
import { Form } from 'antd';
import { Container, FormInnerWrap, Title } from './style';
import AmityLog from '@assets/images/ams-logo.png';
import {
  DangerToast,
  FormItem,
  LoadingIcon,
  PrimaryButton,
  TextInput,
  Button,
} from '@amityco/diana-bot';
import { Font16G3, RedNote, ToastWrap } from '@components/UtilitiesComponent';

export type IRequestResetPasswordComponent = {
  loading: boolean;
  errorMessage: string;
  onRequestResetPassword: ({ email }: { email: string }) => void;
  onCancel: () => void;
};

export const RequestResetPassword: React.FC<IRequestResetPasswordComponent> = ({
  loading,
  errorMessage,
  onRequestResetPassword,
  onCancel,
}: IRequestResetPasswordComponent) => {
  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} height={40} />
        <Title level={4}>Reset password</Title>

        <Form onFinish={onRequestResetPassword} layout="vertical" requiredMark="optional">
          {errorMessage && (
            <ToastWrap>
              <DangerToast align="flex-start">{errorMessage}</DangerToast>
            </ToastWrap>
          )}
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email!',
              },
            ]}
            label={
              <Font16G3>
                Enter your email <RedNote>*</RedNote>
              </Font16G3>
            }
          >
            <TextInput size="large" type="text" placeholder="Email" />
          </FormItem>
          <PrimaryButton
            htmlType="submit"
            size="large"
            disabled={loading}
            data-testid="test-get-code-btn"
          >
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} />}Get Code
          </PrimaryButton>

          <Button size="large" onClick={onCancel} data-testid="test-cancel-btn">
            Cancel
          </Button>
        </Form>
      </FormInnerWrap>
    </Container>
  );
};
