import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { IFilterState } from '../useDashboard';
export type IFetchWrapProps<T, Y> = {
  filter: Y;
  queryKey?: any[];
  fetchFn: (filter: Y, signal?: AbortSignal) => Promise<T>;
  renderFn: ({
    isLoading,
    data,
    isError,
    refetch,
  }: {
    isLoading: boolean;
    data?: T;
    isError: boolean;
    refetch: () => void;
  }) => JSX.Element;
};

const typedMemo: <T>(c: T) => T = React.memo;

export const FetchWrap = typedMemo(
  <T, Y>({ filter, queryKey, fetchFn, renderFn }: IFetchWrapProps<T, Y>) => {
    const { isLoading, data, isError, refetch } = useQuery({
      queryKey: queryKey,
      queryFn: ({ signal }) => fetchFn(filter, signal),
      refetchOnWindowFocus: false,
      retry: 0,
    });
    return renderFn({ isLoading, data, isError, refetch });
  },
);
