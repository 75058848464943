import React from 'react';
import 'antd/dist/antd.min.css';
import { BrowserRouter } from 'react-router-dom';
import { DianaProvider } from '@amityco/diana-bot';
import { Reset } from 'styled-reset';
import * as i18n from '@configs/i18n';
import { QueryClientProvider } from '@tanstack/react-query';
import {
  AlertProvider,
  AuthProvider,
  PromptProvider,
  MemberProvider,
  NetworkSettingProvider,
} from '@providers';
import MainRoute from '@routes';
import { BASE_URL } from '@configs';
import { QUERY_CLIENT } from '@configs';

function App() {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      <BrowserRouter basename={BASE_URL}>
        <AuthProvider>
          <DianaProvider languages={i18n}>
            <AlertProvider>
              <MemberProvider>
                <NetworkSettingProvider>
                  <PromptProvider>
                    <Reset />
                    <MainRoute />
                  </PromptProvider>
                </NetworkSettingProvider>
              </MemberProvider>
            </AlertProvider>
          </DianaProvider>
        </AuthProvider>
      </BrowserRouter>
      <div className="hidden">version{APP_VERSION}</div>
    </QueryClientProvider>
  );
}

export default App;
