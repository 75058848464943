import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardTitleExtra = styled.div`
  border-radius: 12px;
  display: flex;
  padding: 4px;
  width: fit-content;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  max-height: 24px;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette?.GREY_9};
  cursor: pointer;
  column-gap: 4px;
  svg {
    width: 8px;
    height: 8px;
  }
`;
