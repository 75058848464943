import React, { useEffect, useState } from 'react';
import WebWorker from '@worker/worker?worker';
import {
  IActiveUserChartData,
  ITotalMessageChartData,
  IUserJourneySankeyData,
  IWorkerMessageOutputEvent,
} from '@types';

type ValidChartDataTypes =
  | ITotalMessageChartData
  | IActiveUserChartData
  | IUserJourneySankeyData[];

export type ICharWrapProps<T, S extends ValidChartDataTypes> = {
  rows: T;
  renderFn: (chartData: S | null, error: boolean) => JSX.Element;
  workerHandler: {
    postMessage: (worker: Worker, rows: T) => void;
    modifier: (e: S, setter: React.Dispatch<React.SetStateAction<S | null>>) => void;
  };
};

export const ChartWrap = <T, S extends ValidChartDataTypes>({
  rows,
  workerHandler,
  renderFn,
}: ICharWrapProps<T, S>) => {
  const [chartData, setChartData] = useState<S | null>(null);
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    if (!window.Worker) return;
    const worker = new WebWorker();
    workerHandler.postMessage(worker, rows);
    worker.onmessage = (e: IWorkerMessageOutputEvent<S>) => {
      workerHandler.modifier(e.data.payload, setChartData);
      if (error) {
        setError(false);
      }
    };
    worker.onerror = (e) => {
      console.error('error generate total message chart', e);
      setError(true);
    };
    return () => {
      worker.terminate();
    };
  }, [rows, workerHandler]);

  return renderFn(chartData, error);
};
