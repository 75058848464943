import { useState, useEffect } from 'react';
import { SocketIO } from '@classes/socket';

export type IUseSocketParams<T> = {
  url: string;
  token: string;
  onNewMessage: (message: T) => void;
};

export const useSocket = <T>({ url, token, onNewMessage }: IUseSocketParams<T>) => {
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    if (!token) return;
    const socket = new SocketIO<T>(url, token);
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('message', onNewMessage);
    socket.on('error', onError);
    socket.on('reconnect', onReconnected);
    socket.on('reconnect_attempt', onReconnectAttempt);
    socket.on('reconnect_error', onReconnectAttemptError);
    socket.on('reconnect_failed', onReconnectFailed);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('message', onNewMessage);
      socket.off('error', onError);
      socket.off('reconnect', onReconnected);
      socket.off('reconnect_attempt', onReconnectAttempt);
      socket.off('reconnect_error', onReconnectAttemptError);
      socket.off('reconnect_failed', onReconnectFailed);
    };
  }, [token]);

  const onConnect = () => {
    setIsConnected(true);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const onReconnected = (attempt: any) => {
    console.warn(attempt);
  };

  const onReconnectAttempt = (attempt: any) => {
    console.warn(attempt);
  };

  const onReconnectAttemptError = (error: any) => {
    console.warn(error);
  };

  const onReconnectFailed = (error: any) => {
    console.error(error);
  };

  return {
    isConnected,
  };
};
