import { client } from '@api';
import { Auth } from 'aws-amplify';
import { AxiosRequestConfig } from 'axios';
import { COOKIE_NAME } from '../constants';
import { setCustomCookie } from './utils';

const addTokenToHeader = (config: AxiosRequestConfig<any>, token: string) => {
  const newConfig = { ...config };
  newConfig.headers!.Authorization = `Bearer ${token}`;
  return newConfig;
};

export const setupInterceptors = (onAuthError: () => void) => {
  client.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    setCustomCookie(COOKIE_NAME.MEGABOT_TOKEN, jwtToken);
    return addTokenToHeader(config, jwtToken);
  });

  client.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response) {
        console.error(err.response);
        const { status } = err.response;
        if (status === 401) {
          onAuthError();
        }
      }
      throw err;
    },
  );
};
