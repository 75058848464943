import React, { useState } from 'react';
import { Form } from 'antd';
import { Container, FormInnerWrap, Title } from './style';
import AmityLog from '@assets/images/ams-logo.png';
import {
  DangerToast,
  FormItem,
  LoadingIcon,
  PasswordInput,
  PrimaryButton,
  Button,
  TextInput,
} from '@amityco/diana-bot';
import {
  Font16G3,
  RedNote,
  Font12G5W400,
  ToastWrap,
  Font14G3W400,
  Font14PrimaryW500,
} from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import Countdown from 'react-countdown';

export type IResetPasswordComponent = {
  loading: boolean;
  errorMessage: string;
  onRequestResetPassword: () => void;
  onResetPassword: ({ code, password }: { code: string; password: string }) => void;
  onGoBack: () => void;
};

const COUNDOWN_TIME = 15000;

export const ResetPassword = ({
  loading,
  errorMessage,
  onRequestResetPassword,
  onResetPassword,
  onGoBack,
}: IResetPasswordComponent) => {
  const [isResending, setResend] = useState(false);
  const t = useTranslation();

  const _renderer = ({ seconds, completed }: { seconds: number; completed: boolean }) => {
    if (completed || !isResending) {
      // Render a completed state
      return (
        <Font14PrimaryW500
          role="button"
          className="cursor-pointer"
          onClick={() => {
            onRequestResetPassword();
            setResend(true);
          }}
        >
          {t('reset.password.resend.button')}
        </Font14PrimaryW500>
      );
    } else {
      // Render a countdown
      return (
        <Font14PrimaryW500 className="cursor-pointer opacity-70">
          00:{seconds < 10 ? `0${seconds}` : seconds}
        </Font14PrimaryW500>
      );
    }
  };
  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} height={40} />
        <Title level={4}>{t('reset.password.page.title')}</Title>

        <Form onFinish={onResetPassword} layout="vertical" requiredMark="optional">
          <ToastWrap>
            {errorMessage && <DangerToast align="flex-start">{errorMessage}</DangerToast>}
          </ToastWrap>
          <FormItem
            name="code"
            rules={[{ required: true, message: t('reset.password.page.enter.code') }]}
            label={
              <Font16G3>
                {t('reset.password.page.enter.code.label')}
                <RedNote>*</RedNote>
              </Font16G3>
            }
          >
            <TextInput size="large" type="text" placeholder="Code" />
          </FormItem>
          <FormItem
            name="password"
            rules={[{ required: true, message: t('reset.password.page.enter.password') }]}
            label={
              <Font16G3>
                {t('reset.password.page.password.label')}
                <RedNote>*</RedNote>
              </Font16G3>
            }
            help={<Font12G5W400>{t('reset.password.page.password.help')}</Font12G5W400>}
          >
            <PasswordInput
              size="large"
              type="password"
              placeholder={t('reset.password.page.password.placeholder')}
              autoComplete="new-password"
            />
          </FormItem>
          <PrimaryButton
            htmlType="submit"
            size="large"
            disabled={loading}
            data-testid="test-reset-password-btn"
          >
            {loading && <LoadingIcon icon={faSyncAlt} />}{' '}
            {t('reset.password.page.submit')}
          </PrimaryButton>
          <Button onClick={onGoBack} size="large" data-testid="test-go-back-btn">
            {t('reset.password.page.go.back')}
          </Button>
        </Form>
      </FormInnerWrap>
      <div className="mt-[40px] text-center">
        <div>
          <Font14G3W400 className="mr-[8px]">
            {t('reset.password.resend.label')}
          </Font14G3W400>

          <Countdown
            date={Date.now() + COUNDOWN_TIME}
            autoStart
            key={Date.now()}
            renderer={_renderer}
            onComplete={() => setResend(false)}
          />
        </div>
        <div className="mt-[8px]">
          <Font12G5W400>{t('reset.password.resend.note')}</Font12G5W400>
        </div>
      </div>
    </Container>
  );
};
