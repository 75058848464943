import { useState } from 'react';

export const useDateRangePickerFilter = () => {
  const [clearDatePicker, setClearDatePicker] = useState(false);
  const [openDatePickers, setOpenDatePickers] = useState(false);
  return {
    clearDatePicker,
    openDatePickers,
    onToggleDatePickers: () => {
      setOpenDatePickers((prev) => !prev);
    },
    onClearDatePicker: (status: boolean) => {
      setClearDatePicker(status);
    },
  };
};
