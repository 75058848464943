import React from 'react';
import { HighchartsReact } from 'highcharts-react-official';
import highchartsSankey from 'highcharts/modules/sankey';
import highchartsHeatmap from 'highcharts/modules/heatmap';
import Highcharts from '@components/Highcharts';
import { Container } from './style';
highchartsSankey(Highcharts);
highchartsHeatmap(Highcharts);
export type IChartProps = HighchartsReact.Props;

export const Chart = (props: IChartProps) => {
  return (
    <Container>
      <HighchartsReact
        {...props}
        options={{
          ...props.options,
          credits: {
            enabled: false,
          },
        }}
        highcharts={Highcharts}
      />
    </Container>
  );
};
