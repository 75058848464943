import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH } from '@configs';
import { ICognitoUser2 } from '@types';

export type IPrivateRoute = {
  authUser: ICognitoUser2 | null;
};

export const PrivateRoute: React.FC<IPrivateRoute> = ({ authUser, children }) => {
  const location = useLocation();

  if (!authUser) {
    if (location.pathname.includes(PATH.REPORT_NO_ID)) {
      return <Navigate to={`${PATH.LOGIN}?redirect=${location.pathname}`} replace />;
    }
    const params = new URL(window.location.href).searchParams;
    const redirect = params.get('redirect');

    if (redirect) {
      return <Navigate to={`${PATH.LOGIN}?redirect=${redirect}`} replace />;
    }
    return <Navigate to={`${PATH.LOGIN}`} replace />;
  }

  return <>{children}</>;
};
