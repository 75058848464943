import React, { useContext, useMemo } from 'react';
import { ISideNavProps, SideNav } from '@amityco/diana-bot';
import {
  EProduct,
  SIDEBAR_FIRST_GROUP_DATA,
  SIDEBAR_SECOND_GROUP_DATA,
  PATH,
  SIDEBAR_CAMPAIGN_GROUP_DATA,
} from '@configs';
import AMS_WHITE_LOGO from '@assets/images/ams-logo-white-bg.png';
import { useAuth, useMember, useNetworkSetting } from '@providers';
import { cloneDeep, compact } from 'lodash';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import { accessControl, hasAccess } from 'utils';
import { PromptContext } from '@providers/PromptProvider/context';
import { ISideNavItem } from '@amityco/diana-bot/dist/components/SideNav/types';
import { Font14WhiteW600 } from '@components/UtilitiesComponent';

// need to wrap inside provider
export const SideBar = React.memo(() => {
  const { onLogout, onClearCognitoUser } = useAuth();
  const { stayFunc } = useContext(PromptContext);
  const { member, onClearMember } = useMember();
  const { data } = useNetworkSetting();
  const alert = useAlert();
  const navigate = useNavigate();
  const {
    canAccessCim,
    canAccessAudience,
    canAccessRichmenu,
    canAccessCampaign,
    canAccessTemplate,
    canAccessEntities,
    canAccessIntent,
    canAccessMapper,
    canAccessLiveChat,
    canAccessGenAi,
    canAccessVariable,
    canAccessBotSetting,
    canAccessChatlogic,
  } = accessControl(member?.role);

  const getSideItem = (canAccess: boolean, item: ISideNavItem) => {
    if (!canAccess) return null;
    return item;
  };

  const genrateSideNav = useMemo(() => {
    if (member) {
      const firstGroupClone = cloneDeep(SIDEBAR_FIRST_GROUP_DATA);
      const secondGroupClone = cloneDeep(SIDEBAR_SECOND_GROUP_DATA);
      const campaignGroup = cloneDeep(SIDEBAR_CAMPAIGN_GROUP_DATA);
      const dashboard = {
        ...firstGroupClone.DASHBOARD,
        children: {
          parentLabel: firstGroupClone.DASHBOARD.label,
          items: compact([
            {
              groupName: 'MENUS',
              items: compact([
                // overview
                firstGroupClone.DASHBOARD_OVERVIEW,
                // user journey
                firstGroupClone.DASHBOARD_USER_JOURNEY,
              ]),
            },
          ]),
        },
      };
      const chatInsight = {
        ...firstGroupClone.CHAT_INSIGHT,
        children: {
          parentLabel: firstGroupClone.CHAT_INSIGHT.label,
          items: compact([
            {
              groupName: 'MENUS',
              items: compact([
                // analyze
                firstGroupClone.JOURNEY_INSIGHT,
              ]),
            },
          ]),
        },
      };
      const chatbot = hasAccess(member.role, EProduct.CHATBOT)
        ? {
            ...secondGroupClone.CHATBOT,
            children: {
              parentLabel: secondGroupClone.CHATBOT.label,
              items: compact([
                {
                  groupName: 'DECISION',
                  items: compact([
                    // entity
                    getSideItem(canAccessEntities(), secondGroupClone.CHATBOT_ENTITY),
                    // intent
                    getSideItem(canAccessIntent(), secondGroupClone.CHATBOT_INTENT),
                    // mapper
                    getSideItem(canAccessMapper(), secondGroupClone.CHATBOT_MAPPER),
                  ]),
                },
                {
                  groupName: 'CONTENT',
                  items: compact([
                    // message template
                    getSideItem(canAccessTemplate(), secondGroupClone.CHATBOT_TEMPLATE),
                    getSideItem(canAccessVariable(), secondGroupClone.CHATBOT_VARIABLE),
                  ]),
                },
                canAccessChatlogic()
                  ? {
                      groupName: 'ADVANCE',
                      items: compact([
                        // chatlogic
                        secondGroupClone.CHATBOT_CHATLOGIC,
                      ]),
                    }
                  : null,
              ]),
            },
          }
        : null;

      const campaign = hasAccess(member.role, EProduct.CAMPAIGN)
        ? {
            ...SIDEBAR_CAMPAIGN_GROUP_DATA.CAMPAIGN,
            children: {
              parentLabel: campaignGroup.CAMPAIGN.label,
              items: compact([
                {
                  groupName: 'MENUS',
                  items: compact([
                    // campaign
                    getSideItem(canAccessCampaign(), campaignGroup.BROADCAST),
                    // segment
                    // getSideItem(canAccessCampaign(), campaignGroup.SEGMENT),
                    // chatads
                    // getSideItem(canAccessChatAds(), campaignGroup.CHATADS),
                  ]),
                },
              ]),
            },
          }
        : null;

      const firstGroup = {
        groupName: '',
        items: compact([
          // dashboard
          dashboard,
          // chat insight
          chatInsight,
          // cim
          getSideItem(canAccessCim(), firstGroupClone.MEMBER_LIST),
          getSideItem(canAccessCim(), firstGroupClone.CHANNEL_MANAGEMENT),
          // audience
          getSideItem(canAccessAudience(), firstGroupClone.AUDIENCE),
          // richmenu
          getSideItem(canAccessRichmenu(), firstGroupClone.RICHMENU),
          chatbot,
          campaign,
          // livechat
          getSideItem(canAccessLiveChat(), firstGroupClone.LIVECHAT),
          // asap
          getSideItem(canAccessGenAi(), firstGroupClone.ASAP),
          // documentation
          firstGroupClone.DOCUMENTATION,
          // bot setting
          getSideItem(canAccessBotSetting(), firstGroupClone.BOT_SETTING),
        ]),
      };

      const menus: ISideNavProps['sideNavItems'] = [
        // first group
        firstGroup,
      ];

      return menus;
    }
    return [];
  }, []);

  return (
    <SideNav
      sideNavItems={genrateSideNav}
      selectedPath={window.location.pathname}
      settings={{
        profileText: 'Profile Settings',
      }}
      workspace={
        !data
          ? undefined
          : {
              img: AMS_WHITE_LOGO,
              el: <Font14WhiteW600>{data ? data.name : ''}</Font14WhiteW600>,
            }
      }
      member={{
        username: member?.attributes.name ?? '',
        email: member?.attributes.email ?? '',
      }}
      onClickMenuItem={(path) => {
        switch (path) {
          case PATH.LIVECHAT:
            window.open(path, '_blank');
            break;
          case PATH.ASAP:
            window.open(path, '_blank');
            break;
          case PATH.DOCUMENTATION:
            window.open(path, '_blank');
            break;
          default:
            navigate(path);
            break;
        }
      }}
      onClickMemberProfile={() => {
        navigate(PATH.CIM_USER_PROFILE);
      }}
      stayFn={stayFunc}
      onLogout={async () => {
        try {
          await onLogout();
          onClearCognitoUser();
          onClearMember();
          navigate(PATH.LOGIN);
        } catch (error) {
          alert.error('Opps! Something went wrong');
        }
      }}
    />
  );
});

SideBar.displayName = 'SideBar';
