import styled from 'styled-components/macro';
import { Divider as CustomDivider } from '@components/UtilitiesComponent';
import { DropdownGroup as DianaDropdownGroup } from '@amityco/diana-bot';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette?.GREY_9};
  border-radius: 8px;
  width: 768px;
  padding: 16px;
  margin-bottom: 16px;
`;

export const Inner = styled.div`
  display: flex;
  position: relative;
  > div:nth-child(3) {
    width: 36px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 250px;
  height: 169px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 16px;
  border: solid 1px ${({ theme }) => theme.palette?.GREY_7};
  background-color: white;
  border-radius: 6px;
  img {
    width: 100%;
  }
`;

export const DetailContainer = styled.div`
  width: 410px;
  margin-right: 24px;
`;

export const DetailItem = styled.div`
  display: flex;
  margin-top: 4px;
  min-height: 20px;
  word-break: break-word;

  span:first-child {
    display: inline-block;
    width: 130px;
  }
  span:nth-child(2) {
    display: inline-block;
    max-width: 280px;
  }
  span:nth-child(3) {
    white-space: pre;
  }
`;

export const Divider = styled(CustomDivider)`
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const DropdownGroup = styled(DianaDropdownGroup)`
  .ant-menu {
    min-width: 180px;
  }
`;
