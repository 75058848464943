import { useMutation } from '@tanstack/react-query';

export type IUseReportParams<TData = unknown, TVariables = unknown> = {
  exportFn: (payload: TVariables) => Promise<TData>;
};

export const useReport = <TData = unknown, TVariables = unknown>({
  exportFn,
}: IUseReportParams<TData, TVariables>) => {
  const exportMut = useMutation<TData, unknown, TVariables>({
    mutationFn: (payload: TVariables) => {
      return exportFn(payload);
    },
  });

  return {
    exportMut,
  };
};
