import { Breadcrumb as CustomBreadCrumb, Link } from './style';
import React from 'react';
import { uniqueId } from 'lodash';

export type IBreadcrumbList = {
  id: string;
  pathname: string;
};

type IBreadCurmbProps = {
  breadcrumbLabel: { [key: string]: string };
  firstRoot: string;
  pathname: string;
};

export const Breadcrumb = ({
  breadcrumbLabel,
  pathname,
  firstRoot,
}: IBreadCurmbProps) => {
  let pathConcat = '';
  const paths = pathname
    .substring(firstRoot.length + 1)
    .split('/')
    .map((item, index) => {
      pathConcat += '/' + item;
      return {
        id: index,
        pathname: item,
        location: firstRoot + pathConcat,
        label: breadcrumbLabel[item],
      };
    });
  const breadCrumbItems = [];

  const generateBreadCrumbItem = (path: string, label: string) => {
    const id = uniqueId();
    return (
      <CustomBreadCrumb.Item key={id} data-testid="test-breadcrumb-item">
        <Link to={path}>{label}</Link>
      </CustomBreadCrumb.Item>
    );
  };

  for (let i = 0; i < paths.length; i++) {
    // show first breadcrumb and current breadcrumb as their label name
    if (i === 0 || i === paths.length - 1)
      breadCrumbItems.push(
        generateBreadCrumbItem(paths[i].location, paths[i].label as string),
      );
    // to check if there are more than 3 breadcrumb items, the middle one is show as '..' (e.g. First Page / .. / Forth Page)
    if (i > 0 && i < paths.length - 1) {
      if (paths.length > 3) {
        if (i === paths.length - 2)
          breadCrumbItems.push(
            generateBreadCrumbItem(paths[paths.length - 2].location, '..'),
          );
      } else
        breadCrumbItems.push(
          generateBreadCrumbItem(paths[i].location, paths[i].label as string),
        );
    }
  }
  return <CustomBreadCrumb>{breadCrumbItems}</CustomBreadCrumb>;
};
