import { ITotalMessageFilterNumber } from '@types';

export const getDashboardNumber = (
  filter: ITotalMessageFilterNumber[],
  total?: number,
) => {
  // dont filter anything
  if (filter.length === 0) return 'N/A';
  // no total
  if (!total) return '0';
  return total;
};
