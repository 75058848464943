import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '@providers';
import { PATH } from '@configs';

export const useResetPassword = () => {
  const {
    tempCognitoRequestEmail,
    resettingPassword,
    errorMessage,
    onClearErrorMessage,
    onResetPassword,
    onRequestResetPassword,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tempCognitoRequestEmail) {
      return navigate(PATH.LOGIN, { replace: true });
    }
  }, [tempCognitoRequestEmail]);

  return {
    tempCognitoRequestEmail,
    resettingPassword,
    errorMessage,
    onResetPassword,
    onRequestResetPassword,
    onClearErrorMessage,
  };
};
