import React from 'react';
import { Card } from '@components/Card';
import { NumberComponent } from '@components/NumberComponent';
import { Spacing } from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faComment,
  faRobot,
  faUserPlus,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { useOverviewContext } from '../providers/OverviewProvider';
import { FetchWrap } from '../FetchWrap';
import { IFilterState } from '../useDashboard';
import {
  IActiveUserCountResponse,
  IAvgMessageResponse,
  IMaxMessageResponse,
  ITotalBotCountResponse,
  ITotalMessageCountResponse,
  ITotalMessageFilterNumber,
} from '@types';
import {
  fetchActiveUserCount,
  fetchAvgMessageCount,
  fetchBotCount,
  fetchMaxMessageCount,
  fetchMessageCount,
  fetchNewUserCount,
} from '@api';
import { CardContent } from '../CardWrap';
import { TOTAL_MESSAGE_NUMBER_FILTER } from '@configs';
import { useMessageInfoNumber } from '../hooks/useMessageInfoNumber';

import {
  generateFilterRequest,
  generateFilterWithMessageSourcesRequest,
  getDashboardNumber,
  getPercentChange,
} from '../fns';
import { Dropdown } from '@components/Dropdown';
import { SelectList } from '@components/SelectList';
import { CardTitleExtra } from './style';

export const CardUserDataInfo = () => {
  const { filter } = useOverviewContext();
  const t = useTranslation();
  const innterFilterTotalMessage = useMessageInfoNumber();
  const innterFilterMaxMessage = useMessageInfoNumber();
  const innterFilterAvgMessage = useMessageInfoNumber();
  return (
    <div className="flex gap-[24px] flex-wrap">
      {/* Active User */}
      <FetchWrap<IActiveUserCountResponse, IFilterState>
        filter={filter}
        queryKey={[
          'activeUserCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
        ]}
        fetchFn={async (
          filter: IFilterState,
          signal?: AbortSignal,
        ): Promise<IActiveUserCountResponse> => {
          const transformFilter = generateFilterRequest(filter);
          return fetchActiveUserCount(`${transformFilter}&topN=10`, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.total.user.card.title')}
                description={t('dashboard.total.user.card.description')}
                topLeftIcon={<FontAwesomeIcon icon={faUsers} />}
              >
                <Spacing height={24} />
                <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                  <NumberComponent
                    num={data?.amount || 0}
                    description={t('dashboard.total.user.number.extra')}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />
      <FetchWrap<IActiveUserCountResponse, IFilterState>
        filter={filter}
        queryKey={[
          'newUserCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
        ]}
        fetchFn={async (
          filter: IFilterState,
          signal?: AbortSignal,
        ): Promise<IActiveUserCountResponse> => {
          const transformFilter = generateFilterRequest(filter);
          return fetchNewUserCount(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.new.user.card.new.user.acquire.response.title')}
                description={t('dashboard.new.user.card.new.user.acquire.description')}
                topLeftIcon={<FontAwesomeIcon icon={faUserPlus} />}
              >
                <Spacing height={24} />
                <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                  <NumberComponent
                    num={data?.amount || 0}
                    description={t('dashboard.total.user.number.extra')}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />

      {/* Total Bot Message */}
      <FetchWrap<ITotalBotCountResponse, IFilterState>
        filter={filter}
        queryKey={[
          'totalBotMessageCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
        ]}
        fetchFn={(filter: IFilterState, signal): Promise<ITotalBotCountResponse> => {
          const transformFilter = generateFilterRequest(filter);
          return fetchBotCount(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.total.message.card.bot.response.title')}
                description={t('dashboard.total.message.card.bot.response.description')}
                topLeftIcon={<FontAwesomeIcon icon={faRobot} />}
              >
                <Spacing height={24} />
                <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                  <NumberComponent
                    num={data?.amount || 0}
                    description={t('dashboard.total.message.number.extra')}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />

      {/* Total Message */}
      <FetchWrap<ITotalMessageCountResponse, IFilterState>
        filter={filter}
        queryKey={[
          'totalMessageCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
          innterFilterTotalMessage.selectedFilter,
        ]}
        fetchFn={async (
          filter: IFilterState,
          signal,
        ): Promise<ITotalMessageCountResponse> => {
          if (innterFilterTotalMessage.selectedFilter.length === 0) {
            return {
              amount: 0,
            };
          }
          const transformFilter = generateFilterWithMessageSourcesRequest(
            filter,
            innterFilterTotalMessage.selectedFilter,
          );

          return fetchMessageCount(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          const totalNumber = getDashboardNumber(
            innterFilterTotalMessage.selectedFilter,
            data?.amount,
          );
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.total.message.card.title')}
                description={t('dashboard.total.message.card.description')}
                topLeftIcon={<FontAwesomeIcon icon={faComment} />}
                titleExtra={
                  <Dropdown
                    visible={innterFilterTotalMessage.visibleDropdown}
                    onVisibleChange={innterFilterTotalMessage.onToggleDropdown}
                    trigger={['click']}
                    overlayWidth={250}
                    overlayContent={
                      <SelectList<ITotalMessageFilterNumber>
                        selected={innterFilterTotalMessage.selectedFilter}
                        options={TOTAL_MESSAGE_NUMBER_FILTER}
                        onClick={innterFilterTotalMessage.onSelect}
                      />
                    }
                  >
                    <CardTitleExtra onClick={innterFilterTotalMessage.onToggleDropdown}>
                      {innterFilterTotalMessage.selectedFilter.map((item) => {
                        return item.icon;
                      })}
                      <FontAwesomeIcon icon={faChevronDown} fontSize={8} />
                    </CardTitleExtra>
                  </Dropdown>
                }
              >
                <Spacing height={24} />
                <CardContent
                  height="50px"
                  isLoading={isLoading}
                  isError={isError}
                  onRetry={refetch}
                >
                  <NumberComponent
                    num={totalNumber}
                    description={t('dashboard.total.message.number.extra')}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />

      {/* Max Message */}
      <FetchWrap<IMaxMessageResponse, IFilterState>
        filter={filter}
        queryKey={[
          'maxMessageCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
          innterFilterMaxMessage.selectedFilter,
        ]}
        fetchFn={async (filter: IFilterState, signal): Promise<IMaxMessageResponse> => {
          if (innterFilterMaxMessage.selectedFilter.length === 0) {
            return {
              previousPeriod: {
                amount: 0,
              },
              targetPeriod: {
                amount: 0,
              },
            };
          }
          const transformFilter = generateFilterWithMessageSourcesRequest(
            filter,
            innterFilterMaxMessage.selectedFilter,
          );

          return fetchMaxMessageCount(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          const percentChange = getPercentChange(
            data?.targetPeriod.amount,
            data?.previousPeriod.amount,
          );
          const totalNumber = getDashboardNumber(
            innterFilterMaxMessage.selectedFilter,
            data?.targetPeriod.amount,
          );
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.max.message.card.title')}
                description={t('dashboard.max.message.card.description')}
                topLeftIcon={<FontAwesomeIcon icon={faComment} />}
                titleExtra={
                  <Dropdown
                    visible={innterFilterMaxMessage.visibleDropdown}
                    onVisibleChange={innterFilterMaxMessage.onToggleDropdown}
                    trigger={['click']}
                    overlayWidth={250}
                    overlayContent={
                      <SelectList<ITotalMessageFilterNumber>
                        selected={innterFilterMaxMessage.selectedFilter}
                        options={TOTAL_MESSAGE_NUMBER_FILTER}
                        onClick={innterFilterMaxMessage.onSelect}
                      />
                    }
                  >
                    <CardTitleExtra onClick={innterFilterMaxMessage.onToggleDropdown}>
                      {innterFilterMaxMessage.selectedFilter.map((item) => {
                        return item.icon;
                      })}
                      <FontAwesomeIcon icon={faChevronDown} fontSize={8} />
                    </CardTitleExtra>
                  </Dropdown>
                }
              >
                <Spacing height={24} />
                <CardContent
                  height="50px"
                  isLoading={isLoading}
                  isError={isError}
                  onRetry={refetch}
                >
                  <NumberComponent
                    num={totalNumber}
                    description={t('dashboard.total.message.number.extra')}
                    percentChange={percentChange}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />

      {/* Avg Message */}
      <FetchWrap<IAvgMessageResponse, IFilterState>
        filter={filter}
        queryKey={[
          'avgMessageCount',
          filter.channels,
          filter.endDate,
          filter.startDate,
          filter.dateInterval,
          innterFilterAvgMessage.selectedFilter,
        ]}
        fetchFn={async (filter: IFilterState, signal): Promise<IAvgMessageResponse> => {
          if (innterFilterAvgMessage.selectedFilter.length === 0) {
            return {
              previousPeriod: {
                amount: 0,
              },
              targetPeriod: {
                amount: 0,
              },
            };
          }
          const transformFilter = generateFilterWithMessageSourcesRequest(
            filter,
            innterFilterAvgMessage.selectedFilter,
          );

          return fetchAvgMessageCount(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          const percentChange = getPercentChange(
            data?.targetPeriod.amount || 0,
            data?.previousPeriod.amount || 0,
          );
          const totalNumber = getDashboardNumber(
            innterFilterAvgMessage.selectedFilter,
            data?.targetPeriod.amount,
          );
          return (
            <div className="flex-1">
              <Card
                minWidth="414px"
                minHeight="180px"
                title={t('dashboard.avg.message.card.title')}
                description={t('dashboard.avg.message.card.description')}
                topLeftIcon={<FontAwesomeIcon icon={faComment} />}
                titleExtra={
                  <Dropdown
                    visible={innterFilterAvgMessage.visibleDropdown}
                    onVisibleChange={innterFilterAvgMessage.onToggleDropdown}
                    trigger={['click']}
                    overlayWidth={250}
                    overlayContent={
                      <SelectList<ITotalMessageFilterNumber>
                        selected={innterFilterAvgMessage.selectedFilter}
                        options={TOTAL_MESSAGE_NUMBER_FILTER}
                        onClick={innterFilterAvgMessage.onSelect}
                      />
                    }
                  >
                    <CardTitleExtra onClick={innterFilterAvgMessage.onToggleDropdown}>
                      {innterFilterAvgMessage.selectedFilter.map((item) => {
                        return item.icon;
                      })}
                      <FontAwesomeIcon icon={faChevronDown} fontSize={8} />
                    </CardTitleExtra>
                  </Dropdown>
                }
              >
                <Spacing height={24} />
                <CardContent
                  height="50px"
                  isLoading={isLoading}
                  isError={isError}
                  onRetry={refetch}
                >
                  <NumberComponent
                    num={totalNumber}
                    description={t('dashboard.total.message.number.extra')}
                    percentChange={percentChange}
                  />
                </CardContent>
              </Card>
            </div>
          );
        }}
      />
    </div>
  );
};
