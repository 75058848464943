import {
  EUSER_JOURNEY_SOURCE,
  IFilterRequestParams,
  ITotalMessageFilterNumber,
  IUserJourneyFilterRequestParams,
} from '@types';
import qs from 'qs';
import { IUserJourneyFilterState } from '../providers/UserJourneyProvider/useJourney';
import { IFilterState } from '../useDashboard';

export const generateFilterRequest = (filter: IFilterState): string => {
  const channelsQs = filter.channels.map((channel) => {
    return channel.channelId;
  });
  const transformFilter: IFilterRequestParams = {
    startIsoStringDate: filter.startDate.format(),
    endIsoStringDate: filter.endDate.format(),
    interval: filter.filterInterval,
    channels: channelsQs.length ? channelsQs : undefined,
  };
  const queryStr = qs.stringify(transformFilter);
  return queryStr;
};

export const generateFilterTopUserRequest = (filter: IFilterState): string => {
  const channelsQs = filter.channels.map((channel) => {
    return channel.channelId;
  });
  const transformFilter: Omit<IFilterRequestParams & { topN: number }, 'interval'> = {
    startIsoStringDate: filter.startDate.format(),
    endIsoStringDate: filter.endDate.format(),
    channels: channelsQs.length ? channelsQs : undefined,
    topN: 10,
  };
  const queryStr = qs.stringify(transformFilter);
  return queryStr;
};

export const generateFilterWithMessageSourcesRequest = (
  filter: IFilterState,
  messageSourcesFilter: ITotalMessageFilterNumber[],
): string => {
  const messageSourceQs = messageSourcesFilter.map((item) => item.value);
  const channelsQs = filter.channels.map((channel) => {
    return channel.channelId;
  });
  const transformFilter: IFilterRequestParams = {
    startIsoStringDate: filter.startDate.format(),
    endIsoStringDate: filter.endDate.format(),
    interval: filter.filterInterval,
    channels: channelsQs.length ? channelsQs : undefined,
    messageSources: messageSourceQs.length ? messageSourceQs : undefined,
  };
  const queryStr = qs.stringify(transformFilter);
  return queryStr;
};

export const generateUserjourneyFilterRequest = (
  input: IUserJourneyFilterState,
): string => {
  let unitId = '';
  if (input.source.type === EUSER_JOURNEY_SOURCE.TEMPLATE) {
    unitId = input.source.selectedTemplate?.id || '';
  }
  if (input.source.type === EUSER_JOURNEY_SOURCE.RICHMENU) {
    unitId = input.source.selectedRichmenu?.id || '';
  }
  const channelsQs = input.channels.map((channel) => {
    return channel.channelId;
  });
  const segmentsQs = input.segments.map((segment) => {
    return segment.id;
  });
  const transformFilter: IUserJourneyFilterRequestParams = {
    startIsoStringDate: input.startDate.format(),
    endIsoStringDate: input.endDate.format(),
    unitId: unitId,
    channels: channelsQs.length ? channelsQs : undefined,
    segmentIds: segmentsQs.length ? segmentsQs : undefined,
  };

  const queryStr = qs.stringify(transformFilter);
  return queryStr;
};
