import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import accessibility from 'highcharts/modules/accessibility';

if (typeof Highcharts === 'object') {
  highchartsMore(Highcharts);
  solidGauge(Highcharts);
  accessibility(Highcharts);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  (function (H) {
    const { defined } = H;

    H.addEvent(H.Legend, 'afterRender', function () {
      const legend = this as Highcharts.Legend & Record<string, any> & { group: any };
      const chart = legend.chart;
      const { custom, useHTML, layout } = legend.options as Highcharts.LegendOptions & {
        custom: {
          scrollableLegendArea: {
            minHeight: number;
            minWidth: number;
          };
        };
      };
      const isHorizontal = layout === 'horizontal';

      if (
        defined(custom) &&
        defined(custom.scrollableLegendArea) &&
        useHTML &&
        legend.group.div
      ) {
        const { minHeight, minWidth } = custom.scrollableLegendArea;

        if (!legend.legendWrapper) {
          // Create additional SVG element to put inside additional div
          // after first render
          legend.legendWrapper = chart.renderer.createElement('svg').attr({
            version: '1.1',
            class: 'highcharts-scrollable-legend',
            height: legend.legendHeight,
            width: isHorizontal
              ? legend.contentGroup.getBBox().width
              : legend.legendWidth,
          });
        }
        const { element } = legend.legendWrapper;
        // Move legend group to the new SVG element
        legend.group.add(legend.legendWrapper);

        // Add SVG element to div
        legend.group.div.appendChild(element);

        // Add style to use native browser scrollbar
        legend.group.div.style.overflow = 'auto';

        if (minHeight) {
          legend.group.div.style.height = minHeight + 'px';
          // Overwrite legend's height
          legend.legendHeight = minHeight;
        }
        if (minWidth) {
          legend.group.div.style.width = minWidth + 'px';
          // Overwrite legend's width
          legend.legendWidth = minWidth;
        }

        legend.align();
        legend.group.element.removeAttribute('transform');
        legend.group.element.style = 'transform: translate(0, 0) !important';
      }
    });
  })(Highcharts);
}

export default Highcharts;
