import React, { FC, useCallback, useRef, useState } from 'react';
import { PromptContext } from './context';
import { DeleteModal } from '@amityco/diana-bot';

export const PromptProvider: FC = ({ children }) => {
  const cbReject = useRef<() => void>();
  const cbResolve = useRef<() => void>();
  const stayFunc = useRef<{
    onSelectFirstPathInternal?: (path: string) => void;
    onSelectSecondPathInternal?: (path: string) => void;
  }>({
    onSelectFirstPathInternal: undefined,
    onSelectSecondPathInternal: undefined,
  });
  const [open, setOpen] = useState(false);

  // if user click stay will call this function to set internal state of sidenav to update highlight path
  // need diana-bot v1.0.115 or above
  const onStay = useCallback(() => {
    if (
      stayFunc.current.onSelectFirstPathInternal &&
      stayFunc.current.onSelectSecondPathInternal
    ) {
      const splitPath = window.location.pathname.split('/');

      if (splitPath.length >= 2) {
        stayFunc.current.onSelectFirstPathInternal(
          `/${window.location.pathname.split('/')[1]}`,
        );
        stayFunc.current.onSelectSecondPathInternal(`/${splitPath[1]}/${splitPath[2]}`);
      }
    }
  }, [stayFunc.current]);

  const showPrompt = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        cbReject.current = reject;
        cbResolve.current = resolve;
        setOpen(true);
      }),
    [],
  );

  const close = useCallback(() => {
    if (cbReject.current) {
      cbReject.current();
      onStay();
    }
    setOpen(false);
  }, []);

  const confirm = useCallback(() => {
    if (cbResolve.current) {
      cbResolve.current();
    }
    setOpen(false);
  }, []);

  return (
    <PromptContext.Provider value={{ showPrompt, stayFunc }}>
      {children}
      <DeleteModal
        centered
        visible={open}
        confirmBtnTxt={'Leave page'}
        cancelBtnTxt={'Stay'}
        isLoading={false}
        handleClose={close}
        handleConfirm={confirm}
      >
        <span>
          Are you sure you want to leave this page? This page that you made will not be
          saved.
        </span>
      </DeleteModal>
    </PromptContext.Provider>
  );
};
