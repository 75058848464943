export type IGenerateOptionAndCursorIdData = {
  id: string;
  name: string;
  [key: string]: any;
};

export const generateOptionAndCursorId = <T extends IGenerateOptionAndCursorIdData>(
  data: T[] | IGenerateOptionAndCursorIdData[],
  maxTake: number,
) => {
  const newData = data.map((item: T | IGenerateOptionAndCursorIdData) => {
    return {
      ...item,
      label: item.name,
      value: item.id,
    };
  });
  return {
    result: newData,
    cursorId: data.length === maxTake ? data[data.length - 1].id : '',
  };
};

export const generateOptionAndCursorIdV2 = <T extends IGenerateOptionAndCursorIdData>(
  data: T[] | IGenerateOptionAndCursorIdData[],
  maxTake: number,
) => {
  const newData = data.map((item: T | IGenerateOptionAndCursorIdData) => {
    return {
      ...item,
      label: item.label,
      value: item.id,
    };
  });
  return {
    result: newData,
    cursorId: data.length === maxTake ? data[data.length - 1].id : '',
  };
};

export const generateDataAndCursorId = <T extends { id: string }>(
  data: T[],
  maxTake: number,
) => {
  return {
    result: data,
    cursorId: data.length === maxTake ? data[data.length - 1].id : '',
  };
};
