import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 24px;
`;
export const TotalMessageGridWrap = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const CardTitleExtra = styled.div`
  border-radius: 12px;
  display: flex;
  padding: 4px;
  width: fit-content;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  max-height: 24px;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette?.GREY_9};
  cursor: pointer;
  column-gap: 4px;
  svg {
    width: 8px;
    height: 8px;
  }
`;
