import dayjs from 'dayjs';

export const formatDate = (time?: string, pattern = 'MMMM D, YYYY HH:mm') => {
  if (!time) return null;
  return dayjs(time).format(pattern);
};

export const getCurrentDate = () => {
  return dayjs();
};

export const getStartOfMonth = (date: dayjs.Dayjs) => {
  return date.startOf('month');
};

export const getDate = (date: string, format?: string) => {
  if (format) return dayjs(date, format);
  return dayjs(date);
};

export const disabledDateBeforeToday = (current: dayjs.Dayjs) => {
  return current.isBefore(getCurrentDate(), 'date');
};

export const getTodayWithFormat = (format = 'DD MMM YYYY') => {
  return dayjs().startOf('day').format(format);
};

export const getNowTime = () => {
  return dayjs().format('HH:mm');
};
