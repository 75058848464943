import React from 'react';
import { Dropdown } from '@components/Dropdown';
import {
  Divider,
  Font14G1W400,
  Font14G5W400,
  Font16G1W500,
  Frame,
  Spacing,
} from '@components/UtilitiesComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { farEllipsisV } from 'iconPack';
import {
  Container,
  IconLeftWrap,
  TopSection,
  ActionItem,
  TitleWrap,
  TopInner,
} from './style';
import { GhostButton } from '@amityco/diana-bot';
import { isEmpty } from 'lodash';

export type ICardProps = {
  title: string;
  description?: string;
  divider?: boolean;
  actionList?: { label: React.ReactNode; value: string; icon?: JSX.Element }[];
  titleExtra?: React.ReactNode;
  topLeftIcon?: React.ReactNode;
  children?: React.ReactNode;
  minWidth?: string;
  minHeight?: string;
  _renderCustomActionList?: () => React.ReactNode;
  onClickAction?: ({
    label,
    value,
    icon,
  }: {
    label: React.ReactNode;
    value: string;
    icon?: JSX.Element;
  }) => void;
};

export const Card = ({
  title,
  divider,
  topLeftIcon,
  description,
  actionList = [],
  titleExtra,
  children,
  minWidth,
  minHeight,
  _renderCustomActionList,
  onClickAction,
}: ICardProps) => {
  const _renderActionList = () => {
    return actionList.map((item) => {
      return (
        <ActionItem
          key={item.value}
          onClick={() => (onClickAction ? onClickAction(item) : false)}
        >
          {item.icon ? item.icon : null}
          <Font14G1W400>{item.label}</Font14G1W400>
        </ActionItem>
      );
    });
  };

  return (
    <Container minWidth={minWidth} minHeight={minHeight}>
      <TopSection>
        <TopInner>
          <div>
            <TitleWrap>
              {topLeftIcon ? <IconLeftWrap>{topLeftIcon}</IconLeftWrap> : null}
              <Font16G1W500>{title}</Font16G1W500>
              {titleExtra ? <div>{titleExtra}</div> : null}
            </TitleWrap>
            {description ? (
              <>
                <Spacing height={4} />
                <Font14G5W400>{description}</Font14G5W400>
              </>
            ) : null}
          </div>
        </TopInner>
        {_renderCustomActionList ? _renderCustomActionList() : null}
        {!isEmpty(actionList) ? (
          <div>
            <Frame centered width={24} height={24}>
              <Dropdown trigger={['click']} overlayContent={_renderActionList()}>
                <GhostButton>
                  <FontAwesomeIcon icon={farEllipsisV} fontSize={18} />
                </GhostButton>
              </Dropdown>
            </Frame>
          </div>
        ) : null}
      </TopSection>
      {divider ? <Divider /> : null}
      {children}
    </Container>
  );
};
