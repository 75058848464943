import LineIcon from '@assets/images/line.png';
import Messenger from '@assets/images/messenger.png';
import Webchat from '@assets/images/webchat_channel.png';
import Custom from '@assets/images/custom_channel.png';
import { IDropDownData } from '@amityco/diana-bot';

export const CHANNEL_API_MAX_FETCH_TAKE = 10;
export const CHANNEL_CREATE_SUCCESS_TEXT = 'Create channel successfully';
export const CHANNEL_CREATE_FAIL_TEXT = 'Create channel failed';

export enum EChannelType {
  LINE = 'LINE',
  FACEBOOK = 'FACEBOOK',
  WEBCHAT = 'WEBCHAT',
  CUSTOM = 'CUSTOM',
  NOT_SET = 'NOT_SET',
}

export const SETTINGS_CHANNEL_FILTER = [
  {
    key: '',
    items: [
      {
        icon: '',
        label: 'All channels',
        value: 'all',
      },
      {
        label: 'LINE',
        value: EChannelType.LINE,
        icon: LineIcon,
      },
      {
        label: 'Facebook',
        value: EChannelType.FACEBOOK,
        icon: Messenger,
      },
      {
        label: 'Webchat',
        value: EChannelType.WEBCHAT,
        icon: Webchat,
      },
      {
        label: 'Custom',
        value: EChannelType.CUSTOM,
        icon: Custom,
      },
    ],
  },
];

export const DROPDOWN_DATA: IDropDownData[] = [
  {
    key: 'CHOOSE YOUR CHANNEL',
    items: [
      {
        label: 'LINE',
        value: EChannelType.LINE,
        icon: LineIcon,
      },
      {
        label: 'Facebook',
        value: EChannelType.FACEBOOK,
        icon: Messenger,
      },
      {
        label: 'Webchat',
        value: EChannelType.WEBCHAT,
        icon: Webchat,
      },
      {
        label: 'Custom',
        value: EChannelType.CUSTOM,
        icon: Custom,
      },
    ],
  },
];
