import React, { createContext, useContext } from 'react';
import { IMemberContext } from './useMember';
import { useMember as useMemberHook } from './useMember';
export const MemberContext = createContext({} as IMemberContext);

export const useMember = () => useContext(MemberContext);

export const MemberProvider: React.FC = ({ children }) => {
  const props = useMemberHook();
  return <MemberContext.Provider value={props}>{children}</MemberContext.Provider>;
};
