import React from 'react';
import { RequestResetPassword as RequestResetPasswordComponent } from '@components/AuthComponent/RequestResetPassword';
import { useAuth } from '@providers';
import { useNavigate } from 'react-router-dom';

const RequestResetPassword = () => {
  const navigate = useNavigate();
  const { loading, errorMessage, onClearErrorMessage, onRequestResetPassword } =
    useAuth();
  return (
    <RequestResetPasswordComponent
      loading={loading}
      errorMessage={errorMessage}
      onRequestResetPassword={onRequestResetPassword}
      onCancel={() => {
        onClearErrorMessage();
        navigate(-1);
      }}
    />
  );
};

export default RequestResetPassword;
