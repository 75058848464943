import React from 'react';
import { Chart } from '@components/Chart';
import { Card } from '@components/Card';
import { ChartContainer, Container } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '@hooks/useTranslation';
import { FetchWrap } from '../FetchWrap';
import { useOverviewContext } from '../providers/OverviewProvider';
import { IFilterState } from '../useDashboard';
import {
  EWorkerMessageType,
  IActiveUserChartResponse,
  IActiveUserChartRow,
  IChartData,
  IFilterRequestParams,
} from '@types';
import qs from 'qs';
import { fetchActiveUserChart } from '@api';
import { ChartWrap } from '../ChartWrap';
import { SeriesColumnOptions } from 'highcharts';
import { CardContent } from '../CardWrap';

export const TotalUserChart = () => {
  const t = useTranslation();
  const { filter } = useOverviewContext();

  return (
    <Container>
      <FetchWrap
        filter={filter}
        queryKey={['activeUserChart', filter]}
        fetchFn={async (
          filter: IFilterState,
          signal?: AbortSignal,
        ): Promise<IActiveUserChartResponse> => {
          const channelsQs = filter.channels.map((channel) => {
            return channel.channelId;
          });
          const transformFilter: IFilterRequestParams = {
            startIsoStringDate: filter.startDate.format(),
            endIsoStringDate: filter.endDate.format(),
            interval: filter.filterInterval,
            channels: channelsQs.length ? channelsQs : undefined,
          };
          const queryStr = qs.stringify(transformFilter);
          return fetchActiveUserChart(queryStr, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          return (
            <Card
              title={t('dashboard.total.user.chart.title')}
              description={t('dashboard.total.user.chart.description')}
              divider
              topLeftIcon={<FontAwesomeIcon icon={faUsers} />}
            >
              <ChartContainer>
                <ChartWrap<IActiveUserChartRow[], IChartData>
                  // #1 process this first
                  rows={data?.rows || []}
                  // #2 process this second
                  workerHandler={{
                    postMessage: (worker: Worker, rows) => {
                      worker.postMessage({
                        type: EWorkerMessageType.ACTIVE_USER_CHART,
                        payload: {
                          rows: rows || [],
                          interval: filter.filterInterval,
                        },
                      });
                    },
                    modifier: (e, setter) => {
                      setter(e);
                    },
                  }}
                  // #3 process this third
                  renderFn={(chartData, error: boolean) => {
                    // selectedFilter.length = 0 display empty chart
                    let newDataSeries: IChartData = {
                      dataSeries: [],
                      dateInterval: [],
                    };
                    // no filter
                    // no raw data
                    // row is empty
                    // chartData is null
                    if (!data || data.rows.length === 0 || !chartData) {
                      newDataSeries = {
                        dataSeries: [
                          {
                            name: 'Series 1',
                            data: [],
                          },
                        ],
                        dateInterval: [],
                      };
                    } else {
                      newDataSeries = chartData;
                    }
                    return (
                      <CardContent
                        isLoading={isLoading}
                        isError={isError || error}
                        onRetry={() => {
                          if (isError) {
                            refetch();
                          }
                        }}
                      >
                        <Chart
                          options={{
                            title: {
                              text: '',
                            },
                            chart: {
                              type: 'line',
                            },
                            legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'top',
                              useHTML: true,

                              labelFormatter: function () {
                                const self = this as any;
                                const userOptions = self.userOptions as unknown as {
                                  data: number[];
                                  isDeleted: boolean;
                                  name: string;
                                };
                                if (userOptions.isDeleted) {
                                  return `
                                      ${self.name}
                                      <span style="color: #BEBEBE;">(removed)</span>
                                  `;
                                }
                                return self.name;
                              },
                            },
                            tooltip: {
                              useHTML: true,
                              shared: true,
                              outside: true,
                              formatter: function () {
                                const self = this as any;

                                return `
                                  <div class="block ml-[-8px] mr-[-8px] p-2 " style="word-break: break-all; background: white;">${self.points
                                    ?.map((p: any) => {
                                      return `<div key={p.point.index} class="flex items-center gap-x-[4px] mb-1">
                                          <div class="block w-[10px] h-[10px] rounded-[100%]" style="background:${
                                            p.color
                                          };"></div>
                                          <span>${p.series.getName()} -> ${p.y}</span>
                                        </div>`;
                                    })
                                    .join('')}</div>
                                `;
                              },
                            },
                            yAxis: {
                              title: {
                                text: '',
                              },
                            },
                            plotOptions: {
                              area: {
                                stacking: 'normal',
                              },
                            },
                            xAxis: {
                              categories: newDataSeries.dateInterval,
                            },
                            series: newDataSeries.dataSeries as SeriesColumnOptions[],
                          }}
                        />
                      </CardContent>
                    );
                  }}
                />
              </ChartContainer>
            </Card>
          );
        }}
      />
    </Container>
  );
};
