import { CAMPAIGN_API_ENDPOINT, client } from '@api';
import {
  IAddActiveRichmenuParams,
  IAddUpcomingRichmenuParams,
  IFetchRichmenuChannelListParams,
  IRichmenuChannelCardBE,
  IUpdateUpcomingRichmenuParams,
} from '@types';

export const fetchRichmenuChannelList = (params?: IFetchRichmenuChannelListParams) => {
  return client.get(`${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels`, {
    params,
  });
};

export const fetchRichmenuChannels = async (params?: IFetchRichmenuChannelListParams) => {
  const { data }: { data: IRichmenuChannelCardBE[] } = await client.get(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels`,
    {
      params,
    },
  );
  return data;
};

export const addActiveRichmenu = (
  channelId: string,
  richmenu: IAddActiveRichmenuParams,
) => {
  return client.post(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/active`,
    { ...richmenu },
  );
};

export const addUpcomingRichmenu = (
  channelId: string,
  richmenu: IAddUpcomingRichmenuParams,
) => {
  return client.post(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/upcoming`,
    { ...richmenu },
  );
};

export const fetchActiveRichmenuList = (
  channelId: string,
  params?: IFetchRichmenuChannelListParams,
) => {
  return client.get(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/active`,
    {
      params: {
        take: params?.take,
        cursorId: params?.cursorId,
        skip: params?.skip,
      },
    },
  );
};

export const fetchUpcomingRichmenuList = (
  channelId: string,
  params?: IFetchRichmenuChannelListParams,
) => {
  return client.get(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/upcoming`,
    {
      params: {
        take: params?.take,
        cursorId: params?.cursorId,
        skip: params?.skip,
      },
    },
  );
};

export const fetchUpcomingRichmenuById = (channelId: string, id: string) => {
  return client.get(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/upcoming/${id}`,
  );
};

export const updateUpcomingRichmenu = (
  channelId: string,
  id: string,
  params: IUpdateUpcomingRichmenuParams,
) => {
  return client.put(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/upcoming/${id}`,
    { ...params },
  );
};

export const deleteActiveRichmenu = (channelId: string, id: string) => {
  return client.delete(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/active/${id}`,
  );
};

export const deleteUpcomingRichmenu = (channelId: string, id: string) => {
  return client.delete(
    `${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}/upcoming/${id}`,
  );
};

export const fetchRichmenuChannelByChannelId = (channelId: string) => {
  return client.get(`${CAMPAIGN_API_ENDPOINT}/api/v1/richmenus/channels/${channelId}`);
};
