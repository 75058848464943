export enum EReportStatusType {
  PENDING = 'PENDING',
  GENERATED = 'GENERATED',
}

export enum EOverviewType {
  DASHBOARD_OVERVIEW = 'dashboard-overview',
}

export enum EUserJourneyType {
  DASHBOARD_USER_JOURNEY = 'dashboard-user-journey',
}
