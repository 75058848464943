import { CIM_API_ENDPOINT } from '@api';
import axios from 'axios';

export const resetPassword = async (email: string, code: string, newPassword: string) => {
  return axios.put(`${CIM_API_ENDPOINT}/api/v1/authentication/password`, {
    email: email,
    password: newPassword,
    verificationCode: code,
  });
};
