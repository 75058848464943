import { AxiosResponse } from 'axios';
import React, { createContext, useContext } from 'react';
export * from './useImageUpload';

export type IImageUploaderFnType = (
  formData: FormData,
) => Promise<AxiosResponse<any, any>>;

export type IImageUploadProviderProps = {
  uploadFn: IImageUploaderFnType;
};

export type IImageUploadProvider = {
  uploader: IImageUploaderFnType;
};

export type IImageUploadFuntional = (
  uploadFn: IImageUploaderFnType,
) => IImageUploaderFnType;

export const ImageUploadContext = createContext<IImageUploadProvider>(
  {} as IImageUploadProvider,
);

export const useImageUploadContext = () => useContext(ImageUploadContext);

export const ImageUploadProvider = ({
  uploadFn,
  children,
}: React.PropsWithChildren<IImageUploadProviderProps>) => {
  const onUpload: IImageUploadFuntional = (uploadFn: IImageUploaderFnType) => {
    return (formData: FormData) => {
      return uploadFn(formData);
    };
  };

  const uploader = onUpload(uploadFn);

  return (
    <ImageUploadContext.Provider value={{ uploader: uploader }}>
      {children}
    </ImageUploadContext.Provider>
  );
};
