import React from 'react';
import { EChannelType } from '@configs';
import LineIcon from '@assets/images/line.png';
import Messenger from '@assets/images/messenger.png';
import Webchat from '@assets/images/webchat_channel.png';
import Custom from '@assets/images/custom_channel.png';
import { IChannelType } from '@amityco/diana-bot';

export type IChannelProps = {
  channelType: IChannelType;
};

export const Channel = ({ channelType }: IChannelProps) => {
  switch (channelType) {
    case EChannelType.LINE:
      return <img src={LineIcon} width={24} height={24} />;
    case EChannelType.FACEBOOK:
      return <img src={Messenger} width={24} height={24} />;
    case EChannelType.WEBCHAT:
      return <img src={Webchat} width={24} height={24} />;
    case EChannelType.CUSTOM:
      return <img src={Custom} width={24} height={24} />;
    default:
      return null;
  }
};
