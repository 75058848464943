import React from 'react';

import { Font24G1W700, Spacing } from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import { PageLayout } from '@layouts';
import { GridContainer, ErrorContainer, Head } from './style';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorState } from '@components/ErrorState';
import { ERROR_DESCRIPTION_DEAULT_MESSAGE, ERROR_TITLE_DEAULT_MESSAGE } from '@configs';
import BOT_ICON from '@assets/images/bot-icon.svg';
import { OverviewFilter } from '../OverviewFilter';
import { OverviewProvider } from '../providers/OverviewProvider';
import { OverviewReportBtn } from '../Overview/ReportBtn';
import { OverviewHeatmap } from '../Overview/OverviewHeatmap';
import { CardUserDataInfo } from '../CardUserDataInfo';
import { TotalMessageChart } from '../TotalMessageChart';
import { OverviewTopUser } from '../Overview/OverviewTopUser';
import { TotalUserChart } from '../TotalUserChart';

const DashboardContent = () => {
  const t = useTranslation();

  return (
    <div className="overflow-auto h-full">
      <PageLayout>
        <OverviewProvider>
          <Head>
            <Font24G1W700>{t('dashboard.title')}</Font24G1W700>
            <div>
              <OverviewReportBtn />
            </div>
          </Head>

          <Spacing height={24} />
          <OverviewFilter />
          <Spacing height={32} />
          <ErrorBoundary
            onError={(error, info) => console.error('ErrorBoundary ', error, info)}
            fallback={
              <ErrorContainer minusHeight={217}>
                <ErrorState
                  img={BOT_ICON}
                  title={ERROR_TITLE_DEAULT_MESSAGE}
                  description={ERROR_DESCRIPTION_DEAULT_MESSAGE}
                />
              </ErrorContainer>
            }
          >
            <CardUserDataInfo />
            <div className="mt-[24px] grid grid-cols-[repeat(auto-fit,minmax(634px,1fr))] gap-[24px]">
              <TotalMessageChart />
              <TotalUserChart />
            </div>
            <GridContainer>
              <div className="col-start-1 col-end-3">
                <OverviewTopUser />
              </div>
              <div className="col-start-1 col-end-3">
                <OverviewHeatmap />
              </div>
            </GridContainer>
          </ErrorBoundary>
        </OverviewProvider>
      </PageLayout>
    </div>
  );
};

export default DashboardContent;
