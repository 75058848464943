/* eslint-disable no-unused-vars */

import { ISideNavItem } from '@amityco/diana-bot/dist/components/SideNav/types';

export const PATH = {
  LOGIN: '/login',
  CHANGE_PASSWORD: '/change-password',
  REQUEST_RESET_PASSWORD: '/request-reset-password',
  RESET_PASSWORD: '/reset-password',
  MEMBERS: '/members',
  MEMBER_EDITOR: '/members/:id',
  CIM_USER_PROFILE: '/user-profile',
  CHANNEL_MANAGEMENT: '/channel-management',

  // Dashboard
  DASHBOARD: '/dashboard',
  DASHBOARD_OVERVIEW: '/dashboard/overview',
  DASHBOARD_USER_JOURNEY: '/dashboard/user-journey',

  // Chat Insight
  CHAT_INSIGHT: '/chat-insight',
  JOURNEY_INSIGHT: '/chat-insight/journey',
  CHAT_INSIGHT_ANALYZE: '/chat-insight/analyze',

  // Audience
  AUDIENCE: '/audience',
  AUDIENCE_PROFILE: '/audience/:id',

  // Richmenu
  RICHMENU: '/richmenu',
  RICHMENU_EDITOR: '/richmenu/editor',
  RICHMENU_EDITOR_WITH_ID: '/richmenu/editor/:id',
  RICHMENU_MANAGEMENT: '/richmenu/management',
  RICHMENU_MANAGEMENT_WITH_ID: '/richmenu/management/:id',
  RICHMENU_MANAGEMENT_EDITOR: '/richmenu/management/:channelId/editor',
  RICHMENU_MANAGEMENT_EDITOR_WITH_ID: '/richmenu/management/:channelId/editor/:id',

  // Chatbot
  CHATBOT: '/chatbot',
  CHATBOT_ENTITY: '/chatbot/entity',
  CHATBOT_INTENT: '/chatbot/intent',
  CHATBOT_MAPPER: '/chatbot/mapper',
  CHATBOT_TEMPLATE: '/chatbot/template',
  CHATBOT_TEMPLATE_EDITOR: '/chatbot/template/editor',
  CHATBOT_TEMPLATE_EDITOR_WITH_ID: '/chatbot/template/editor/:id',
  CHATBOT_VARIABLE: '/chatbot/bot-variable',
  CHATBOT_CHATLOGIC: '/chatbot/chatlogic',

  // Campaign
  CAMPAIGN: '/campaign',
  BROADCAST: '/campaign/broadcast',
  BROADCAST_EDITOR: '/campaign/broadcast/editor',
  BROADCAST_EDITOR_WITH_ID: '/campaign/broadcast/editor/:id',
  BROADCAST_STATUS: '/campaign/broadcast/status/:id',
  CAMPAIGN_TEMPLATE: '/campaign/template',
  CAMPAIGN_TEMPLATE_EDITOR: '/campaign/template/editor',
  CAMPAIGN_TEMPLATE_EDITOR_WITH_ID: '/campaign/template/editor/:id',

  CHATADS: '/campaign/chatads',
  CHATADS_EDITOR: '/campaign/chatads/editor',
  CHATADS_EDITOR_WITH_ID: '/campaign/chatads/editor/:id',

  SEGMENT: '/campaign/segment',

  REPORT_NO_ID: '/chatbot/report',
  REPORT: '/chatbot/report/:id',

  LIVECHAT: (import.meta.env.VITE_LIVECHAT_ENDPOINT as string) ?? '',
  ASAP: (import.meta.env.VITE_ASAP_ENDPOINT as string) ?? '',
  DOCUMENTATION: 'https://docs.amitysolutions.com/amity-solutions',
  BOT_SETTING: '/bot-setting',
  ERROR: '/error',
  NOT_FOUND: '/404',
};

export const SIDEBAR_FIRST_GROUP_DATA: { [key: string]: ISideNavItem } = {
  MEMBER_LIST: {
    label: 'Users',
    path: PATH.MEMBERS,
    prefixIcon: ['fas', 'users'],
  },
  CHANNEL_MANAGEMENT: {
    label: 'Channels',
    path: PATH.CHANNEL_MANAGEMENT,
    prefixIcon: ['fas', 'signal-stream'],
  },
  DASHBOARD: {
    label: 'Dashboard',
    path: PATH.DASHBOARD,
    prefixIcon: ['fas', 'chart-line'],
    suffixIcon: ['fas', 'chevron-right'],
  },
  DASHBOARD_OVERVIEW: {
    label: 'Overview',
    path: PATH.DASHBOARD_OVERVIEW,
  },
  DASHBOARD_USER_JOURNEY: {
    label: 'User journey',
    path: PATH.DASHBOARD_USER_JOURNEY,
  },
  CHAT_INSIGHT: {
    label: 'Chat Insight',
    path: PATH.CHAT_INSIGHT,
    prefixIcon: ['fas', 'message-dots'],
    suffixIcon: ['fas', 'chevron-right'],
  },
  CHAT_INSIGHT_ANALYZE: {
    label: 'Analyze',
    path: PATH.CHAT_INSIGHT_ANALYZE,
  },
  JOURNEY_INSIGHT: {
    label: 'Journey',
    path: PATH.JOURNEY_INSIGHT,
  },
  AUDIENCE: {
    label: 'Audience',
    path: PATH.AUDIENCE,
    prefixIcon: ['fas', 'head-side-headphones'],
  },
  RICHMENU: {
    label: 'Richmenu',
    path: PATH.RICHMENU,
    prefixIcon: ['fas', 'grid-horizontal'],
  },
  LIVECHAT: {
    label: 'Live Chat',
    path: PATH.LIVECHAT,
    prefixIcon: ['fas', 'headset'],
    suffixIcon: ['fas', 'arrow-up-right-from-square'],
    isExternal: true,
  },
  ASAP: {
    label: 'Generative AI',
    path: PATH.ASAP,
    prefixIcon: ['fas', 'sparkles'],
    suffixIcon: ['fas', 'arrow-up-right-from-square'],
    isExternal: true,
  },
  DOCUMENTATION: {
    label: 'Documentation',
    path: PATH.DOCUMENTATION,
    prefixIcon: ['fas', 'book'],
    suffixIcon: ['fas', 'arrow-up-right-from-square'],
    isExternal: true,
  },
  BOT_SETTING: {
    label: 'Setting',
    path: PATH.BOT_SETTING,
    prefixIcon: ['fas', 'robot-astromech'],
    topDivider: true,
  },
};

export const SIDEBAR_SECOND_GROUP_DATA: { [key: string]: ISideNavItem } = {
  CHATBOT: {
    label: 'Chatbot',
    path: PATH.CHATBOT,
    prefixIcon: ['fas', 'robot'],
    suffixIcon: ['fas', 'chevron-right'],
  },
  CHATBOT_TEMPLATE: {
    label: 'Template',
    path: PATH.CHATBOT_TEMPLATE,
  },
  CHATBOT_VARIABLE: {
    label: 'Variable',
    path: PATH.CHATBOT_VARIABLE,
  },
  CHATBOT_ENTITY: {
    label: 'Entity',
    path: PATH.CHATBOT_ENTITY,
  },
  CHATBOT_INTENT: {
    label: 'Intent',
    path: PATH.CHATBOT_INTENT,
  },
  CHATBOT_MAPPER: {
    label: 'Mapper',
    path: PATH.CHATBOT_MAPPER,
  },

  CAMPAIGN: {
    label: 'Campaign',
    path: PATH.CAMPAIGN,
    prefixIcon: ['fas', 'megaphone'],
    suffixIcon: ['fas', 'chevron-right'],
  },
  CAMPAIGN_TEMPLATE: {
    label: 'Message template',
    path: PATH.CAMPAIGN_TEMPLATE,
  },

  CHATBOT_CHATLOGIC: {
    label: 'Chatlogic',
    path: PATH.CHATBOT_CHATLOGIC,
  },
};

export const SIDEBAR_CAMPAIGN_GROUP_DATA: { [key: string]: ISideNavItem } = {
  CAMPAIGN: {
    label: 'Campaign',
    path: '/campaign',
    prefixIcon: ['fas', 'megaphone'],
    suffixIcon: ['fas', 'chevron-right'],
  },
  BROADCAST: {
    label: 'Broadcast',
    path: PATH.BROADCAST,
  },
  SEGMENT: {
    label: 'Segment',
    path: PATH.SEGMENT,
  },
  CHATADS: {
    label: 'Line ChatAds',
    path: PATH.CHATADS,
  },
};
