import { useNavigate } from 'react-router-dom';
import { useAuth } from '@providers';
import { useEffect } from 'react';
import { PATH } from '@configs';

export const useChangePassword = () => {
  const { tempCognitoUser, loading, errorMessage, onChangePassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tempCognitoUser) {
      return navigate(PATH.LOGIN, { replace: true });
    }
  }, [tempCognitoUser]);

  return {
    loading,
    errorMessage,
    onChangePassword,
  };
};

export default useChangePassword;
