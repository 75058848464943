export enum EBotVariableType {
  STRING = 'string',
}

export enum EBotScope {
  BOT = 'bot',
  ENVIRONMENT = 'environment',
}

export const BOT_VARIABLE_MIN_NAME_LENGTH = 1;
export const BOT_VARIABLE_MAX_NAME_LENGTH = 100;
export const BOT_VARIABLE_MIN_STRING_VALUE_LENGTH = 1;
export const BOT_VARIABLE_MAX_STRING_VALUE_LENGTH = 500;

export const BOT_VARIABLE_CREATE_SUCCESS_MESSAGE = 'Variable created successfully';
export const BOT_VARIABLE_UPDATE_SUCCESS_MESSAGE = 'Variable updated successfully';
export const BOT_VARIABLE_DELETE_SUCCESS_MESSAGE = 'Variable deleted successfully';

export const BOT_VARIABLE_VALIDATE_MESSAGE = {
  NAME_REQUIRED: 'Name is required',
  NAME_MIN: 'Name must be at least 1 characters',
  NAME_MAX: 'Name must be less than 100 characters',
  NAME_REGEX: '(1-100 characters, a-z, 0-9, _, -)',

  VALUE_REQUIRED: 'Value is required',
  VALUE_MIN: 'Value must be at least 1 characters',
  VALUE_MAX: 'Value must be less than 500 characters',
  VALUE_REGEX: '(a-z, ก-ฮ, 0-9, _, -, space bar, all special characters)',
};

export const SCOPE_OPTIONS = [
  {
    label: 'Bot',
    value: EBotScope.BOT,
  },
  {
    label: 'Environment',
    value: EBotScope.ENVIRONMENT,
  },
];

export const INITIAL_BOT_VARIABLE_FORM = () => {
  return {
    id: '',
    name: '',
    type: EBotVariableType.STRING,
    value: '',
    scope: EBotScope.BOT,
  };
};
