import { io, Socket } from 'socket.io-client';

export class SocketIO<T> {
  private socket: Socket;

  constructor(url: string, token: string) {
    this.socket = io(url, {
      path: '/socket/',
      transports: ['websocket'],
      auth: {
        token,
      },
    });
  }

  get status() {
    return this.socket.connected;
  }

  public on(event: string, callback: (payload: T) => void): void {
    this.socket.on(event, callback);
  }

  public off(event: string, callback: (payload: T) => void): void {
    this.socket.off(event, callback);
  }

  public emit<P>(event: string, payload: P): void {
    this.socket.emit(event, payload);
  }

  public disconnect(): void {
    this.socket.disconnect();
  }
}
