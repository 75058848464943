import React from 'react';
import { ChangePassword as ChangePasswordComponent } from '@components/AuthComponent/ChangePassword';
import useChangePassword from './useChangePassword';

const ChangePassword: React.FC = () => {
  const { loading, errorMessage, onChangePassword } = useChangePassword();
  return (
    <ChangePasswordComponent
      loading={loading}
      errorMessage={errorMessage}
      onChangePassword={onChangePassword}
    />
  );
};

export default ChangePassword;
