import styled from 'styled-components';
import {
  GhostButton as DianaGhostButton,
  PrimaryButton as DianaPrimaryButton,
} from '@amityco/diana-bot';

export const TopSection = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrap = styled.div`
  display: flex;
  column-gap: 24px;
`;

export const GhostButton = styled(DianaGhostButton)`
  color: ${({ theme }) => theme.palette?.GREY_3};
  svg {
    margin-right: 8px;
  }
`;

export const PrimaryButton = styled(DianaPrimaryButton)`
  svg {
    margin-right: 8px;
  }
`;
