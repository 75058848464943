import React, { Suspense } from 'react';
import { LoadingCircle, LoadingWrap } from '@amityco/diana-bot';
import styled from 'styled-components';
import { Outlet } from 'react-router';
import { SideBar } from '@sidebar';

const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const ContentContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const AppLayout: React.FC = () => {
  return (
    <>
      <AppContainer>
        <SideBar />
        <Suspense
          fallback={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
        >
          <ContentContainer>{<Outlet />}</ContentContainer>
        </Suspense>
      </AppContainer>
    </>
  );
};
