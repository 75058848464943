import React from 'react';
import { ResetPassword as ResetPasswordComponent } from '@components/AuthComponent/ResetPassword';
import { useNavigate } from 'react-router-dom';
import { useResetPassword } from './useResetPassword';

const ResetPassword = () => {
  const {
    resettingPassword,
    errorMessage,
    tempCognitoRequestEmail,
    onRequestResetPassword,
    onClearErrorMessage,
    onResetPassword,
  } = useResetPassword();
  const navigate = useNavigate();
  return (
    <ResetPasswordComponent
      loading={resettingPassword}
      errorMessage={errorMessage}
      onResetPassword={({ code, password }) => {
        onResetPassword({ code, newPassword: password });
      }}
      onRequestResetPassword={() => {
        if (!tempCognitoRequestEmail) {
          return;
        }
        onRequestResetPassword({ email: tempCognitoRequestEmail });
      }}
      onGoBack={() => {
        onClearErrorMessage();
        navigate(-1);
      }}
    />
  );
};

export default ResetPassword;
