import React from 'react';
import {
  DangerToast,
  FormItem,
  LoadingIcon,
  PasswordInput,
  PrimaryButton,
} from '@amityco/diana-bot';
import { Form } from 'antd';
import { Container, FormInnerWrap, Title } from './style';
import { Font12, Font16G3, RedNote, ToastWrap } from '@components/UtilitiesComponent';
import AmityLog from '@assets/images/ams-logo.png';
import { useTranslation } from '@hooks/useTranslation';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

export type IChangePasswordComponent = {
  errorMessage: string;
  loading: boolean;
  onChangePassword: ({ password }: { password: string }) => void;
};

export const ChangePassword = ({
  loading,
  errorMessage,
  onChangePassword,
}: IChangePasswordComponent) => {
  const t = useTranslation();
  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} height={40} />
        <Title level={4}>{t('change.password.page.title')}</Title>

        <Form onFinish={onChangePassword} layout="vertical" requiredMark="optional">
          <ToastWrap>
            {errorMessage && <DangerToast align="flex-start">{errorMessage}</DangerToast>}
          </ToastWrap>
          <FormItem
            name="password"
            rules={[
              {
                required: true,
                message: t('change.password.page.enter.email'),
              },
            ]}
            label={
              <Font16G3>
                {t('change.password.page.password.label')} <RedNote>*</RedNote>
              </Font16G3>
            }
            help={<Font12>{t('change.password.page.password.help')}</Font12>}
          >
            <PasswordInput
              size="large"
              type="password"
              placeholder={t('change.password.page.password.placeholder')}
              autoComplete="set-password"
            />
          </FormItem>
          <PrimaryButton
            htmlType="submit"
            size="large"
            disabled={loading}
            data-testid="test-submit-btn"
          >
            {loading && <LoadingIcon icon={faSyncAlt} data-testid="test-loading-icon" />}
            {t('change.password.page.submit')}
          </PrimaryButton>
        </Form>
      </FormInnerWrap>
    </Container>
  );
};
