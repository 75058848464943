export const awsExports = {
  aws_project_region: import.meta.env.VITE_COGNITO_REGION,
  authenticationFlowType: import.meta.env.VITE_COGNITO_AUTH_FLOW_TYPE,
  Auth: {
    region: import.meta.env.VITE_COGNITO_AUTH_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_AUTH_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_AUTH_USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_COGNITO_AUTH_COOKIE_DOMAIN,
      path: import.meta.env.VITE_COGNITO_AUTH_COOKIE_PATH,
      expires:
        typeof import.meta.env.VITE_COGNITO_AUTH_COOKIE_EXPIRES === 'string'
          ? parseFloat(import.meta.env.VITE_COGNITO_AUTH_COOKIE_EXPIRES)
          : 1,
      secure: import.meta.env.VITE_COGNITO_AUTH_COOKIE_SECURE ? true : false,
      sameSite: 'strict',
    },
  },
};
