import React from 'react';
import {
  Container,
  DetailContainer,
  DetailItem,
  ImageContainer,
  Inner,
  Divider,
  DropdownGroup,
} from './style';
import { IDropDownData, SecondaryButton } from '@amityco/diana-bot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Font14G1W500, Font14G5W500 } from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import {
  ERICHMENU_SETTING_MENU_BEHAVIOR,
  ERICHMENU_SETTING_TARGET_AUDIENCE,
} from '@configs';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISegment } from '@types';

type RichmenuChannelItemProps = {
  id: string;
  imgUrl: string;
  richmenuName: string;
  menuBarLabel: string;
  menuBehavior: string;
  priority?: string;
  targetAudience: string;
  dropdownMenu: IDropDownData[];
  segment?: ISegment;
  dateToAdd: string;
  onConfirmDelete: () => void;
};

export const RichmenuChannelItem = ({
  id,
  imgUrl,
  richmenuName,
  menuBarLabel,
  menuBehavior,
  priority,
  targetAudience,
  segment,
  dateToAdd,
  dropdownMenu,
  onConfirmDelete,
}: RichmenuChannelItemProps) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container>
      <Inner>
        <ImageContainer>
          <img src={imgUrl} />
        </ImageContainer>
        <DetailContainer>
          <Font14G1W500>{t('richmenu.channel.list.item.detail.title')}</Font14G1W500>
          <Divider />
          <DetailItem>
            <Font14G5W500>
              {t('richmenu.channel.list.item.name.label')} {' :'}
            </Font14G5W500>
            <Font14G1W500>{richmenuName}</Font14G1W500>
          </DetailItem>
          <DetailItem>
            <Font14G5W500>
              {t('richmenu.channel.list.item.menu.bar.label.label')} {' :'}
            </Font14G5W500>
            <Font14G1W500>{menuBarLabel}</Font14G1W500>
          </DetailItem>
          <DetailItem>
            <Font14G5W500>
              {t('richmenu.channel.list.item.menu.behavior.label')} {' :'}
            </Font14G5W500>
            <Font14G1W500>
              {menuBehavior === ERICHMENU_SETTING_MENU_BEHAVIOR.AUTO_DISPLAYED
                ? t('richmenu.channel.list.auto.display.value')
                : t('richmenu.channel.list.collapes.value')}
            </Font14G1W500>
          </DetailItem>
          {priority ? (
            <DetailItem>
              <Font14G5W500>
                {t('richmenu.channel.list.item.priority.label')} {' :'}
              </Font14G5W500>
              <Font14G1W500>{priority}</Font14G1W500>
            </DetailItem>
          ) : null}
          <DetailItem>
            <Font14G5W500>
              {t('richmenu.channel.list.item.target.audience.label')} {' :'}
            </Font14G5W500>
            {targetAudience === ERICHMENU_SETTING_TARGET_AUDIENCE.ALL ? (
              <>
                <Font14G1W500>{t('richmenu.channel.list.all.user.value')}</Font14G1W500>
                <Font14G5W500>
                  {' '}
                  {t('richmenu.channel.list.all.user.description')}
                </Font14G5W500>
              </>
            ) : (
              <Font14G1W500>{segment?.name}</Font14G1W500>
            )}
          </DetailItem>
          <DetailItem>
            <Font14G5W500>
              {t('richmenu.channel.list.item.date.to.add.label')} {' :'}
            </Font14G5W500>
            <Font14G1W500>{dateToAdd}</Font14G1W500>
          </DetailItem>
        </DetailContainer>
        <DropdownGroup
          placement="bottomLeft"
          btn={(setFunction) => {
            return (
              <SecondaryButton
                data-testid="test-option-btn"
                icon={<FontAwesomeIcon icon={['fas', 'ellipsis-v']} />}
                onClick={() => setFunction(true)}
              />
            );
          }}
          data={dropdownMenu}
          handleSelectItem={(item) => {
            if (item.value === 'edit') {
              navigate(`${location.pathname}/editor/${id}`);
            } else {
              onConfirmDelete();
            }
          }}
        />
      </Inner>
    </Container>
  );
};
