import React from 'react';
import { useNetworkSetting as useNetworkSettingHook } from './useNetworkSetting';
const NetworkSettingContext = React.createContext(
  {} as ReturnType<typeof useNetworkSettingHook>,
);
export const useNetworkSetting = () => React.useContext(NetworkSettingContext);

export const NetworkSettingProvider: React.FC = ({ children }) => {
  const result = useNetworkSettingHook();
  return (
    <NetworkSettingContext.Provider value={result}>
      {children}
    </NetworkSettingContext.Provider>
  );
};
