import React from 'react';
import { Login as LoginComponent } from '@components/AuthComponent/Login';
import { useAuth } from '@providers';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@configs';

const Login = () => {
  const { loading, errorMessage, onClearErrorMessage, onLogin } = useAuth();
  const navigate = useNavigate();
  const onForgotPassword = () => {
    onClearErrorMessage();
    navigate(PATH.REQUEST_RESET_PASSWORD);
  };

  return (
    <>
      <LoginComponent
        loading={loading}
        errorMessage={errorMessage}
        onLogin={onLogin}
        onForgotPassword={onForgotPassword}
        appVersion={`Version ${APP_VERSION}`}
      />
    </>
  );
};

export default Login;
