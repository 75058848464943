export const enum EProduct {
  CHATBOT = 'chatbot',
  CAMPAIGN = 'campaign',
  RICHMENU = 'richmenu',
  LIVECHAT = 'livechat',
  GENAI = 'genai',
  REPORT = 'report',
  CIM = 'cim',
}

export const enum EChatbotSubProduct {
  INTENTS = 'intents',
  ENTITIES = 'entities',
  RICHMENU = 'richmenus',
  MAPPER = 'mappers',
  TEMPLATE = 'templates',
  AUDIENCE = 'audience',
  SEGMENT = 'segments',
  LINKLOGIC = 'linklogic',
  CHATLOGIC = 'chatlogic',
}

export const enum ECampaignSubProduct {
  CHATADS = 'chatads',
  RICHMENU = 'richmenus',
  TEMPLATE = 'templates',
  AUDIENCE = 'audience',
  SEGMENT = 'segments',
}

export const enum ECognitoRole {
  CIM_ADMIN = 'cim.admin',
  CIM_USER = 'cim.user',

  CHATBOT_ADMIN = 'chatbot.admin',
  CHATBOT_EDITOR = 'chatbot.editor',
  CHATBOT_DEVELOPER = 'chatbot.developer',
  CHATBOT_MODERATOR = 'chatbot.moderator',

  CAMPAIGN_ADMIN = 'campaign.admin',
  CAMPAIGN_EDITOR = 'campaign.editor',
  CAMPAIGN_MODERATOR = 'campaign.moderator',

  LIVECHAT_ADMIN = 'livechat.admin',
  LIVECHAT_SUPERVISOR = 'livechat.supervisor',
  LIVECHAT_AGENT = 'livechat.agent',

  GENAI_ADMIN = 'genai.admin',
  GENAI_USER = 'genai.user',
}
