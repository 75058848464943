import styled from 'styled-components/macro';

export const ErrorContainer = styled.div<{ minusHeight: number }>`
  width: 100%;
  height: ${({ minusHeight }) => `calc(100vh - ${minusHeight}px)`};
  display: flex;
  justify-content: center;
`;

export const GridContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 24px;
  padding-bottom: 24px;
  padding-right: 4px;
  flex: 1;
`;

export const Head = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const BetaBadge = styled.div`
  width: 48px;
  border-radius: 6px;
  padding: 2px 4px;
  text-align: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #04be8c;
`;
