import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';

export const getToken = async () => {
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();
  return jwtToken;
};

export const clearCognitoCookie = () => {
  if (document.cookie) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      if (
        cookie.toLowerCase().includes('cognito') ||
        cookie.toLowerCase().includes('amplify')
      ) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = getCookie(name, '');
      }
    }
  }
};

export const setCustomCookie = (name: string, value: string) => {
  if (!value || !name) return;
  document.cookie = `${name}=${value}; domain=${getDomain()}; path=/;`;
};

export const removeCustomCookie = (name: string) => {
  if (!name) return;
  Cookies.remove(name, {
    domain: getDomain(),
  });
};

export const getCookie = (name: string, value: string) => {
  return `${name}=${value};expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${getDomain()};`;
};

export const getDomain = () => {
  return import.meta.env.PROD
    ? `${import.meta.env.VITE_COGNITO_AUTH_COOKIE_DOMAIN}`
    : 'localhost';
};
