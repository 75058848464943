import { IDropDownData } from '@amityco/diana-bot';
import { IActionList } from '@components/ButtonAction/ButtonActionProvider';
import { MESSAGE_ACTION_TYPE } from '@configs';
import { IRichmenuActionFE } from '@types';
import { uniqueId } from 'lodash';

export const RICHMENU_API_MAX_TAKE = 30;
export const RICHMENU_CHANNEL_API_MAX_TAKE = 30;

// main richmenu page
export const enum ERICHMENU_TAB_MENU {
  RICHMENU = 'Rich Menu',
  MANAGEMENT = 'Channels',
}

// create and edit richmenu
export const enum ERICHMENU_TYPE {
  ORIGINAL = 'original',
  COMPACT = 'compact',
}

export const enum EORIGINAL_PATTERN {
  PATTERN_1 = 'pattern1',
  PATTERN_2 = 'pattern2',
  PATTERN_3 = 'pattern3',
  PATTERN_4 = 'pattern4',
  PATTERN_5 = 'pattern5',
  PATTERN_6 = 'pattern6',
  PATTERN_7 = 'pattern7',
  PATTERN_8 = 'pattern8',
}

export const enum ECOMPACT_PATTERN {
  PATTERN_1 = 'pattern1',
  PATTERN_2 = 'pattern2',
  PATTERN_3 = 'pattern3',
  PATTERN_4 = 'pattern4',
  PATTERN_5 = 'pattern5',
}

export const RICHMENU_WIDTH = 500;
export const RICHMENU_ORIGINAL_HEIGHT = 337;
export const RICHMENU_COMPACT_HEIGHT = 168.5;

export const RICHMENU_PERCENT_FULL_HEIGHT = 100;
export const RICHMENU_PERCENT_FULL_WIDTH = 100;

export const RICHMENU_LINE_MIN_WIDTH = 800;
export const RICHMENU_LINE_MAX_WIDTH = 2500;
export const RICHMENU_LINE_ORIGINAL_MIN_HEIGH = 540;
export const RICHMENU_LINE_COMPACT_MIN_HEIGH = 270;
export const RICHMENU_LINE_ORIGINAL_MAX_HEIGH = 1686;
export const RICHMENU_LINE_COMPACT_MAX_HEIGH = 843;

export const RICHMENU_ACTION_LABEL_TITLE = 'Action label';
export const RICHMENU_ACTION_LABEL_PLACEHOLDER = 'Enter your label';

export const RICHMENU_ACTION_LIST: IActionList[] = [
  {
    label: 'Send template',
    value: 'template_action',
  },
  {
    label: 'Go to Flow',
    value: 'flow_action',
  },
  {
    label: 'Open website',
    value: 'uri',
  },
  {
    label: 'Call number',
    value: 'call_action',
  },
  {
    label: 'Receive as text',
    value: 'message',
  },
  // {
  //   label: 'Location',
  //   value: 'location',
  // },
];

export const RICHMENU_LIST_MENU: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Edit',
        value: 'edit',
      },
      {
        label: 'Duplicate',
        value: 'duplicate',
      },
      {
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

export const RICHMENU_LOCK_LIST_MENU: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Preview',
        value: 'preview',
      },
      {
        label: 'Duplicate',
        value: 'duplicate',
      },
    ],
  },
];

export const RICHMENU_ACTIVE_LIST_MENU: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

export const RICHMENU_UPCOMING_LIST_MENU: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Edit & preview',
        value: 'edit',
      },
      {
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

export const RICHMENU_CREATED_DATE_PATTERN = 'DD/MM/YYYY';

// use for generating pattern regions

export const RICHMENU_DEFAULT_ACTION = (): IRichmenuActionFE => ({
  id: uniqueId(),
  label: '',
  type: MESSAGE_ACTION_TYPE.TEMPLATE,
  templateName: '',
  isPopover: false,
});

export const RICHMENU_DEFAULT_CONFIG = {
  isChanging: false,
  new: false,
};

export const enum ERICHMENU_ERROR_MESSAGE {
  DUPLICATED_NAME = 'Unique constraint failed. (Richmenu_hash_key)',
  DUPLICATED_NAME_TITLE = 'Rich menus name already exists',
  DUPLICATED_NAME_DESCRIPTION = 'Please change your rich menus name',
}

export const RICHMENU_TEMPLATE_DOWNLOAD_URL = `https://${
  import.meta.env.VITE_S3_ASSET_FILE_BUCKET
}.s3.ap-southeast-1.amazonaws.com/templates/RichMenu_DesignTemplate.zip`;

export const RICHMENU_MAX_REGIONS = 20;
