import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  height: 44px;
  align-items: flex-end;
  column-gap: 12px;
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const GreenGrowth = styled.div`
  display: flex;
  border-radius: 6px;
  background: #cdf2e8;
  column-gap: 2px;
  padding: 2px 4px;
  align-items: center;
`;
export const RedGrowth = styled.div`
  display: flex;
  border-radius: 6px;
  background: #feeaec;
  column-gap: 2px;
  padding: 2px 4px;
  align-items: center;
`;
