import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 372px;
  height: 100%;
`;

export const IconFrame = styled.div`
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-weight: 300;
    font-size: 72px;
    color: #bdbdbd;
  }
`;

export const TitleFrame = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #9e9e9e;
    text-align: center;
  }
`;

export const SecondaryFrame = styled.div`
  margin-top: 4px;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #9e9e9e;
  }
`;
export const ErrorContainer = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  > div {
    width: 100%;
  }
`;
