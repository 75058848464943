import { MAPPER_SEARCH_INTENT_DEBOUNCE_TIME } from '@configs';
import { assign, createMachine } from 'xstate';

export * from './fns/generateOption';

export type IAutoSearchMachineParams = {
  id: string;
  fetchOnInit?: boolean;
};

export type IAutoSearchMachineContext<T> = {
  keyword: string;
  data: T[];
  cursorId: string;
  errorMessage: {
    title: string;
    description: string;
  };
};

export type AutoSearchMachineEvent<T> =
  | { type: 'IDLE' }
  | { type: 'UPDATE_KEYWORD'; keyword: string }
  | { type: 'LOAD_MORE' }
  | { type: 'done.invoke.firstFetchData'; data: { result: T[]; cursorId: string } }
  | { type: 'done.invoke.loadMore'; data: { result: T[]; cursorId: string } }
  | { type: 'done.invoke.searchData'; data: { result: T[]; cursorId: string } }
  | { type: 'FETCH' }
  | { type: 'CLEAR' }
  | { type: 'CLEAR_PARAMS' };

export const autoSearchMachine = <T>({
  id,
  fetchOnInit = true,
}: IAutoSearchMachineParams) => {
  return createMachine<IAutoSearchMachineContext<T>, AutoSearchMachineEvent<T>>(
    {
      id,
      initial: fetchOnInit ? 'firstFetching' : 'idle',
      context: {
        keyword: '',
        data: [] as T[],
        cursorId: '',
        errorMessage: {
          title: '',
          description: '',
        },
      },
      states: {
        idle: {},
        firstFetching: {
          invoke: {
            id: 'firstFetchData',
            src: 'fetchData',
            onDone: {
              target: 'firstFetchSucceeded',
              actions: ['replaceData', 'updateCursorId'],
            },
            onError: {
              target: 'firstFetchFailed',
            },
          },
        },
        firstFetchSucceeded: {},
        firstFetchFailed: {},
        debounceSearch: {
          after: {
            [MAPPER_SEARCH_INTENT_DEBOUNCE_TIME]: 'searching',
          },
        },
        searching: {
          invoke: {
            id: 'searchData',
            src: 'fetchData',
            onDone: {
              target: 'idle',
              actions: ['replaceData', 'updateCursorId'],
            },
            onError: {
              target: 'idle',
            },
          },
        },
        loadingMore: {
          invoke: {
            id: 'loadMore',
            src: 'loadMore',
            onDone: {
              target: 'idle',
              actions: ['pushMoreData', 'updateCursorId'],
            },
            onError: {
              target: 'idle',
            },
          },
        },
      },
      on: {
        UPDATE_KEYWORD: {
          target: 'debounceSearch',
          actions: [
            assign({
              keyword: (context, event) => {
                if (event.type === 'UPDATE_KEYWORD') return event.keyword;
                return context.keyword;
              },
              cursorId: (_, _event) => '',
            }),
          ],
        },
        LOAD_MORE: {
          target: 'loadingMore',
          cond: (context) => !!context.cursorId,
        },
        FETCH: {
          target: 'searching',
        },
        IDLE: {
          target: 'idle',
        },
        CLEAR: {
          target: 'idle',
          actions: 'clearData',
        },
        CLEAR_PARAMS: {
          target: 'idle',
          actions: 'clearParams',
        },
      },
    },
    {
      actions: {
        clearParams: assign({
          keyword: (_context, _event) => {
            return '';
          },
          cursorId: (_context, _event) => {
            return '';
          },
        }),
        clearData: assign({
          data: (context, event) => {
            if (event.type === 'CLEAR') return [];
            return context.data;
          },
        }),
        replaceData: assign({
          data: (context, event) => {
            if (
              event.type === 'done.invoke.firstFetchData' ||
              event.type === 'done.invoke.searchData'
            ) {
              return event.data.result as T[];
            }
            return context.data;
          },
        }),
        pushMoreData: assign({
          data: (context, event) => {
            if (event.type !== 'done.invoke.loadMore') return context.data;
            return [...context.data, ...event.data.result] as T[];
          },
        }),
        updateCursorId: assign({
          cursorId: (_, event) => {
            if (
              event.type === 'done.invoke.firstFetchData' ||
              event.type === 'done.invoke.loadMore' ||
              event.type === 'done.invoke.searchData'
            ) {
              return event.data.cursorId;
            }
            return '';
          },
        }),
      },
    },
  );
};
