import { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import { CIM_API_ENDPOINT } from '@api';

export const changePassword = (
  user: CognitoUser,
  password: string,
  temporaryPassword: string,
) => {
  if (!user || !temporaryPassword)
    return Promise.reject('User is empty or temporary password is invalid');
  return axios.post(`${CIM_API_ENDPOINT}/api/v1/authentication/password`, {
    email: user.getUsername(),
    password: password,
    temporaryPassword: temporaryPassword,
  });
};
