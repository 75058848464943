import { fetchTopUser } from '@api';
import { Card } from '@components/Card';
import { CardContent } from '@components/Card/CardContent';
import { FetchWrap } from '@components/FetchWrap';
import { NumListItem } from '@components/NumListItem';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@hooks/useTranslation';
import { generateFilterTopUserRequest, getPercentChange } from '@pages/dashboard/fns';
import { useOverviewContext } from '@pages/dashboard/providers/OverviewProvider';
import { IFilterState } from '@pages/dashboard/useDashboard';
import { ITopUserResponse } from '@types';
import { chunk } from 'lodash';
import React from 'react';

export const OverviewTopUser = () => {
  const { filter } = useOverviewContext();
  const t = useTranslation();
  return (
    <FetchWrap<ITopUserResponse[], IFilterState>
      filter={filter}
      queryKey={[
        'topUser',
        filter.channels,
        filter.endDate,
        filter.startDate,
        filter.dateInterval,
      ]}
      fetchFn={async (filter: IFilterState, signal): Promise<ITopUserResponse[]> => {
        const transformFilter = generateFilterTopUserRequest(filter);
        const data = await fetchTopUser(`${transformFilter}`, signal);

        return data;
      }}
      renderFn={({ isLoading, data, isError, refetch }) => {
        const divider = data && data.length > 4 ? data.length / 2 : 4;
        const rounder = divider ? Math.round(divider) : 0;
        const chunker = chunk(data, rounder);
        let running = 0;

        return (
          <div className="flex-1">
            <Card
              minWidth="414px"
              minHeight="180px"
              title={t('dashboard.top.user.card.title')}
              description={t('dashboard.top.user.card.description')}
              topLeftIcon={<FontAwesomeIcon icon={faStar} />}
              divider
            >
              <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                <div className="grid gap-y-[20px] gap-x-[24px] grid-rows-2 grid-cols-2">
                  {chunker?.map((item, index) => {
                    return item.map((sub, subIndex) => {
                      running += 1;
                      const percentChange = getPercentChange(
                        sub?.targetPeriod.amount || 0,
                        sub?.previousPeriod.amount || 0,
                      );
                      return (
                        <div
                          key={`${index}${subIndex}`}
                          style={{
                            gridColumnStart: index + 1,
                            gridRowStart: subIndex + 1,
                          }}
                        >
                          <NumListItem
                            no={running}
                            key={sub.userId}
                            title={sub.userDisplayName}
                            description={`(${sub.targetPeriod.amount})`}
                            percentChange={percentChange}
                          />
                        </div>
                      );
                    });
                  })}
                </div>
              </CardContent>
            </Card>
          </div>
        );
      }}
    />
  );
};
