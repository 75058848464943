import { IOption } from '@amityco/diana-bot';
import { ISubmitCustomDatePayload } from '@components/DateRangePicker';
import { DATE_INTERVAL, EDateInterval, EFilterInterval, FILTER_INTERVAL } from '@configs';
import { clearChannel, selectChannel } from '@pages/dashboard/fns';
import { IChannelApiResponse } from '@types';
import dayjs from 'dayjs';
import { useState } from 'react';

export type IFilterState = {
  filterInterval: EFilterInterval;
  dateInterval: IOption;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  channels: IChannelApiResponse[];
};

// will use later
export const useOverview = () => {
  const [filter, setFilter] = useState<IFilterState>({
    filterInterval: FILTER_INTERVAL[0].value,
    dateInterval: DATE_INTERVAL[1],
    // last 24 hours
    startDate: dayjs().subtract(1, 'day'),
    // end of today
    endDate: dayjs(),
    // channel
    channels: [],
  });
  return {
    filter,
    onSelectChannel: (payload: IChannelApiResponse) => {
      selectChannel(filter.channels, payload, setFilter);
    },
    onClearChannel: () => {
      clearChannel(filter.channels, setFilter);
    },
    onChangeCustomDate: (payload: ISubmitCustomDatePayload) => {
      setFilter((prev) => ({
        ...prev,
        startDate: payload.startTime,
        endDate: payload.endTime,
        dateInterval: DATE_INTERVAL[0],
      }));
    },
    onChangeFilterInterval: (value: EFilterInterval) => {
      setFilter((prev) => ({ ...prev, filterInterval: value }));
    },
    onChangeDateInterval: (item: (typeof DATE_INTERVAL)[0]) => {
      switch (item.value) {
        case EDateInterval.LAST_24_H:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(1, 'day'),
            endDate: dayjs(),
          }));
          break;
        case EDateInterval.LAST_7_D:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(7, 'day'),
            endDate: dayjs(),
          }));
          break;
        case EDateInterval.LAST_30_D:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(30, 'day'),
            endDate: dayjs(),
          }));
          break;
        case EDateInterval.LAST_3_M:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(3, 'month'),
            endDate: dayjs(),
          }));
          break;
        case EDateInterval.LAST_6_M:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(6, 'month'),
            endDate: dayjs(),
          }));
          break;
        // last 12 M
        default:
          setFilter((prev) => ({
            ...prev,
            dateInterval: item,
            startDate: dayjs().subtract(12, 'month'),
            endDate: dayjs(),
          }));
          break;
      }
    },
  };
};
