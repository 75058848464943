import { Font12G1W400, Font40G1W700 } from '@components/UtilitiesComponent';
import React from 'react';
import { Container, RightSection } from './style';
import millify from 'millify';
import CurrencyFormat from 'react-currency-format';
import { Percent } from '@components/Percent';
export type INumberComponentProps = {
  num: number | string;
  description?: string;
  precision?: number;
  percentChange?: number;
};

export const NumberComponent = ({
  num,
  description,
  precision,
  percentChange,
}: INumberComponentProps) => {
  const _renderNumber = (val: number | string) => {
    if (typeof val === 'string') {
      return val;
    }
    // render normal number if 1-99999
    if (val < 100000) {
      return (
        <CurrencyFormat
          value={val}
          displayType={'text'}
          thousandSeparator={true}
          prefix={''}
          renderText={(value) => <>{value}</>}
        />
      );
    }

    return millify(val, { precision: precision ? precision : 3 });
  };

  return (
    <Container>
      <Font40G1W700>{_renderNumber(num)}</Font40G1W700>
      <RightSection>
        {description && <Font12G1W400>{description}</Font12G1W400>}
        {percentChange ? <Percent num={percentChange} /> : null}
      </RightSection>
    </Container>
  );
};
