import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding-right: 4px;
`;

export const LeftGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 8px;
  .ant-select-selector {
    width: 150px !important;
    height: 36px !important;
  }
`;

export const RightGroup = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 4px 2px;
  gap: 2px;
  align-items: center;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette?.GREY_7};
`;

export const CustomButton = styled.button`
  padding: 6px 12px;
  min-width: 75px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 8px;
  :hover {
    background-color: ${({ theme }) => theme.palette?.GREY_9};
  }
`;

export const NormalBtn = styled.button`
  padding: 6px 12px;
  min-width: 75px;
  height: 32px;
  align-items: center;
  display: flex;
  cursor: pointer;
  background: transparent;
  border: none;
  justify-content: center;
  border-radius: 8px;
  :hover {
    background-color: ${({ theme }) => theme.palette?.GREY_9};
  }
`;

export const ColumnDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette?.GREY_7};
`;

export const SelectedDateIntervalWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  background-color: ${({ theme }) => theme.palette?.GREY_9};
  border-radius: 8px;
  padding: 6px 12px;
  white-space: nowrap;
`;

export const SelectedCustomDateWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  background-color: ${({ theme }) => theme.palette?.GREY_9};
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  white-space: nowrap;
`;
