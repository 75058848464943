import React from 'react';
import { Card } from '@components/Card';
import { CardContent } from '@components/Card/CardContent';
import { Chart } from '@components/Chart';
import { Dropdown } from '@components/Dropdown';
import { FetchWrap } from '@components/FetchWrap';
import { SelectList } from '@components/SelectList';
import { TOTAL_MESSAGE_NUMBER_FILTER_CHART } from '@configs';
import { faChevronDown, faComments } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from '@hooks/useTranslation';
import { useOverviewContext } from '@pages/dashboard/providers/OverviewProvider';
import { CardTitleExtra, ChartContainer } from '@pages/dashboard/TotalMessageChart/style';
import { useTotalMessageChart } from '@pages/dashboard/TotalMessageChart/useTotalMessageChart';
import { IFilterState } from '@pages/dashboard/useDashboard';
import { IOverviewHeatmapResponse, ITotalMessageFilterNumber } from '@types';
import { generateFilterWithMessageSourcesRequest } from '@pages/dashboard/fns';
import { fetchHeatmap } from '@api';

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
// Create a reordered array
const reorderedDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const HOURS = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
];

// Function to map the data to the reordered yAxis
const reorderData = (
  data: Array<number[]>,
  originalOrder: typeof DAYS,
  newOrder: typeof reorderedDays,
) => {
  const orderMap = newOrder.reduce((acc: { [key: string]: number }, day, index) => {
    acc[day] = index;
    return acc;
  }, {});

  return data.map((point) => {
    const [x, y, value] = point;
    const newY = orderMap[originalOrder[y]];
    return [x, newY, value];
  });
};

export const OverviewHeatmap = () => {
  const t = useTranslation();
  const { visibleDropdown, selectedFilter, onSelect, onToggleDropdown } =
    useTotalMessageChart();
  const { filter } = useOverviewContext();
  return (
    <FetchWrap
      filter={filter}
      queryKey={[
        'overview_heatmap',
        filter.channels,
        filter.endDate,
        filter.startDate,
        filter.dateInterval,
        selectedFilter,
      ]}
      fetchFn={async (
        filter: IFilterState,
        signal?: AbortSignal,
      ): Promise<IOverviewHeatmapResponse> => {
        if (selectedFilter.length === 0) {
          return {
            result: {
              aggregate_data: {
                max_message_count: 0,
                min_message_count: 0,
              },
              hourly_data: [],
            },
          };
        }
        const transformFilter = generateFilterWithMessageSourcesRequest(
          filter,
          selectedFilter,
        );

        return fetchHeatmap(transformFilter, signal);
      }}
      renderFn={({ isLoading, data, isError, refetch }) => {
        const seriesData: Array<number[]> = [];
        for (let i = 0; i < DAYS.length; i++) {
          for (let j = 0; j < HOURS.length; j++) {
            const item = data?.result.hourly_data.find((item) => {
              return item.day_of_week === i && item.hour_of_day === j;
            });
            if (item) {
              seriesData.push([j, i, item.avg_message_count]);
            } else {
              seriesData.push([j, i, 0]);
            }
          }
        }

        const reorderedData = reorderData(seriesData, DAYS, reorderedDays);
        return (
          <Card
            title={t('dashboard.heatmap.chart.title')}
            description={t('dashboard.heatmap.chart.description')}
            divider
            titleExtra={
              <Dropdown
                visible={visibleDropdown}
                onVisibleChange={onToggleDropdown}
                trigger={['click']}
                overlayWidth={250}
                overlayContent={
                  <SelectList<ITotalMessageFilterNumber>
                    selected={selectedFilter}
                    options={TOTAL_MESSAGE_NUMBER_FILTER_CHART}
                    onClick={onSelect}
                  />
                }
              >
                <CardTitleExtra onClick={onToggleDropdown}>
                  {selectedFilter.map((item) => {
                    return item.icon;
                  })}
                  <FontAwesomeIcon icon={faChevronDown} fontSize={8} />
                </CardTitleExtra>
              </Dropdown>
            }
            topLeftIcon={<FontAwesomeIcon icon={faComments} fontSize={20} />}
          >
            <ChartContainer>
              <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                <Chart
                  options={{
                    title: {
                      text: '',
                    },
                    chart: {
                      type: 'heatmap',
                    },
                    xAxis: {
                      categories: HOURS,
                    },

                    yAxis: {
                      categories: reorderedDays,
                      title: {
                        text: '',
                      },
                      reversed: true,
                    },
                    legend: {
                      align: 'right',
                      layout: 'vertical',
                      margin: 0,
                      verticalAlign: 'top',
                      y: -10,
                      symbolHeight: 350,
                    },
                    colorAxis: {
                      reversed: false,
                      min: 0,
                      minColor: '#FFFFFF',
                      maxColor: '#04BE8C',
                    },
                    series: [
                      {
                        type: 'heatmap',
                        name: '',
                        borderWidth: 1,
                        data: reorderedData,
                        dataLabels: {
                          enabled: true,
                          color: '#000000',
                        },
                      },
                    ],
                    tooltip: {
                      formatter: function () {
                        return (
                          (this as any).series.yAxis.categories[(this as any).point.y] +
                          ' ' +
                          (this as any).series.xAxis.categories[(this as any).point.x] +
                          ': ' +
                          (this as any).point.value +
                          ' messages'
                        );
                      },
                    },
                  }}
                />
              </CardContent>
            </ChartContainer>
          </Card>
        );
      }}
    />
  );
};
