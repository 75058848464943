import React from 'react';
import styled from 'styled-components/macro';
import { Provider as AlertProviderLib, positions } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
};

const AlertTemplateContainer = styled.div`
  background: rgba(66, 66, 66, 0.97);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-width: 564px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 18px;
  top: 40px;
  color: white;
  word-break: break-word;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  width: 24px;
  font-size: 24px;
  color: ${({ theme }) => theme.palette?.RED};
`;

const SuccessIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  width: 24px;
  font-size: 24px;
  color: ${({ theme }) => theme.palette?.GREEN};
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  width: 24px;
  font-size: 24px;
  color: ${({ theme }) => theme.palette?.YELLOW};
`;

const AlertTemplate = ({ style, message, options }: any) => {
  return (
    <AlertTemplateContainer style={style}>
      {options.type === 'error' && <ErrorIcon icon={['fas', 'exclamation-circle']} />}
      {options.type === 'success' && <SuccessIcon icon={['fas', 'check-circle']} />}
      {options.type === 'info' && <InfoIcon icon={['fas', 'triangle-exclamation']} />}
      {message}
    </AlertTemplateContainer>
  );
};

export const AlertProvider: React.FC = ({ children }) => {
  return (
    <AlertProviderLib
      template={AlertTemplate}
      {...options}
      containerStyle={{
        zIndex: 9999,
      }}
    >
      {children}
    </AlertProviderLib>
  );
};
