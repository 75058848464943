import styled from 'styled-components';

export const PageLayout = styled.div`
  height: 100%;
  padding: 24px 24px 0;
  margin: 0 auto;
  @media (min-width: 1511px) {
    padding: 24px 0px 0;
    width: 90%;
  }
`;
