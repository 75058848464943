import React, { createContext, useContext, ReactNode } from 'react';
import { PATH } from '@configs';
import { useAuthProvider, IUserAuth } from './useAuthProvider';

export type IAuthProvider = {
  children: ReactNode;
  [key: string]: any;
};

export const AuthContext = createContext({} as IUserAuth);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  return (
    <AuthContext.Provider value={useAuthProvider({ redirectUrl: PATH.ERROR })}>
      {children}
    </AuthContext.Provider>
  );
};
