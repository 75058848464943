import { DatetimePickerAction, MessageAction } from '@line/bot-sdk';
import {
  IAssignTicketAction,
  IAssignTicketActionApi,
  ISecondaryActionApi,
} from './assignTicketAction';
import { ICallAction } from './callAction';
import { IFlowAction, IFlowActionApi } from './flowAction';
import { ILocationAction } from './locationAction';
import { ITemplateAction } from './templateAction';
import { IUriAction } from './uriAction';

export type IMessageAction = {
  id: string;
  isPopover: boolean;
  label?: string;
} & (
  | MessageAction
  | DatetimePickerAction
  | IUriAction
  | ICallAction
  | IFlowAction
  | ITemplateAction
  | ILocationAction
  | IAssignTicketAction
  | {
      type: 'camera';
    }
);

export type IMessageActionApi = {
  id: string;
  label?: string;
} & (
  | MessageAction
  | DatetimePickerAction
  | IUriAction
  | ICallAction
  | IFlowActionApi
  | ITemplateAction
  | ILocationAction
  | IAssignTicketActionApi
  | {
      type: 'camera';
    }
);

export const enum EMessageActionType {
  Message = 'message',
  Uri = 'uri',
  CallAction = 'call_action',
  TemplateAction = 'template_action',
  Camera = 'camera',
  DatetimePicker = 'datetimepicker',
  LocationAction = 'location',
  AssignTicketAction = 'assign_ticket',
}

export type IActionComponentProps = {
  onSubmit: (action: IMessageAction, actionIndex: number) => void;
  onCancel: (actionIndex: number) => void;
  onDelete: (actionIndex: number) => void;
  onActionPopoverChange: (actionIndex: number) => void;
};

export * from './assignTicketAction';
