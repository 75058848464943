import React, { createContext, useContext } from 'react';

import { useOverview } from './useOverview';

const OverviewContext = createContext({} as ReturnType<typeof useOverview>);

export const useOverviewContext = () => useContext(OverviewContext);

export const OverviewProvider: React.FC = ({ children }) => {
  const handler = useOverview();

  return <OverviewContext.Provider value={handler}>{children}</OverviewContext.Provider>;
};
