import styled from 'styled-components/macro';

export const Container = styled.div<{ height?: string }>`
  width: 100%;
  height: ${({ height }) => height || '100%'};
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  margin-top: 14px;
`;

export const RetryButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  color: ${({ theme }) => theme.palette?.PRIMARY};
  cursor: pointer;
`;
