export const ERROR_TITLE_DEAULT_MESSAGE = 'Oops! something went wrong';

export const ERROR_DESCRIPTION_DEAULT_MESSAGE = 'Please try again later';

export const ERROR_DEFAULT_CLOSE_TEXT = 'OK';

export const ERROR_MAPPING: { [key: string]: string } = {
  'Unique constraint failed. (Template_networkId_name_key)':
    'Template name already existing',
};

export const ERROR_USER_NOT_LOGIN = 'User not logged in';

export const ERROR_INTENT_MAPPING: {
  [key: string]: { title?: string; description: string };
} = {
  'Unique constraint failed. (Intent_networkId_name_key)': {
    title: 'This intent name already exists',
    description: 'Please change your intent name.',
  },
  'Unique constraint failed. (Intent_networkId_samples_key)': {
    title: 'Do not use the same samples',
    description: 'Please change your sample.',
  },
};

export const ERROR_ENTITY_MAPPING: {
  [key: string]: { title: string; description: string };
} = {
  'Unique constraint failed. (Entity_networkId_name_key)': {
    title: 'This entity name already exists',
    description: 'Please change your entity name.',
  },
};

export const ERROR_BOT_VARIABLE_MAPPING: {
  [key: string]: { title: string; description: string };
} = {
  'Unique constraint failed. (BotVariable_networkId_name_key)': {
    title: 'This variable name already exists',
    description: 'Please change your variable name.',
  },
};

export const ERROR_CONNECT_CHANNEL: {
  [key: string]: { title: string; description?: string };
} = {
  'Unique constraint failed. (Channel_networkId_channelId_key)': {
    title: 'This channel already exists',
  },
};

export const ERROR_CAMPAIGN_EDITOR_MAPPING: {
  [key: string]: { title: string; description?: string };
} = {
  'Unique constraint failed. (Broadcast_hash_key)': {
    title: 'This campaign name already exists',
    description: 'Please change your campaign name.',
  },
};

export const ERROR_SEGMENT_MAPPING: {
  [key: string]: { title: string; description?: string };
} = {
  'Unique constraint failed. (Segment_networkId_name_key)': {
    title: 'Segment already existing',
  },
};

export const MESSAGE_ACTION_ERROR = {
  LABEL_REQUIRED: 'label is required',
  LABEL_OUT_OF_RANGE: (num: number) =>
    `the characters should less than ${num} characters`,
  MESSAGE_REQUIRED: 'message is required',
  PHONE_REQUIRED: 'phone number is required',
  URL_REQUIRED: 'url is required',
  URL_INVALID: 'url is invalid',
  TEMPLATE_REQUIRED: 'template must be selected',
  FLOW_ENGINE_REQUIRED: 'flow engine must be selected',
  FLOW_ACTION_REQUIRED: 'flow action must be selected',
  TEAM_REQUIRED: 'team must be selected',
};

export const ERROR_NO_PERMISSION = 'You are not allowed to access this product';
