import styled from 'styled-components/macro';

export const Container = styled.div``;

export const SelectedWrap = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
`;

export const Wrap = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`;

export const DropdownLabel = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
`;
