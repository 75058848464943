import styled from 'styled-components/macro';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Link as RouterDomLink } from 'react-router-dom';

export const Breadcrumb = styled(AntdBreadcrumb)`
  span:last-child {
    span > a {
      color: ${({ theme }) => theme.palette?.GREY_1};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;

export const Link = styled(RouterDomLink)`
  color: inherit !important;
`;
