// richmenu management page

export const RICHMENU_BREADCRUMB_LABEL = {
  richmenu: 'Rich menu',
  management: 'Management',
  id: '',
  editor: 'Link Rich Menu',
};

export const enum ERICHMENU_SETTING_MENU_BEHAVIOR {
  AUTO_DISPLAYED = 'auto-displayed',
  COLLAPSED = 'collapsed',
}

export const enum ERICHMENU_SETTING_TARGET_AUDIENCE {
  ALL = 'all',
  SEGMENT = 'segment',
}

export const enum ERICHMENU_SETTING_ADD_TIME {
  NOW = 'now',
  CUSTOM = 'custom',
}

export const RICHMENU_MENU_BAR_LABEL_DEFAULT = 'Menu';

export const enum ERICHMENU_SETTING_TYPE {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
}

export const RICHMENU_CHANNEL_LIST_MENU = [
  {
    key: '',
    items: [
      {
        label: 'Edit & preview',
        value: 'edit',
      },
      {
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

export const RICHMENU_API_MAX_FETCH_TAKE = 10;

export const enum EEDIT_BEHAVIOR {
  ADD = 'add',
  UPDATE = 'update',
}
