import { useRichmenuChannel } from '..';
import { useActor } from '@xstate/react';
import { useEffect, useState } from 'react';
import { IRichmenuSettingType } from '@types';
import { useAlert } from 'react-alert';
import { useTranslation } from '@hooks/useTranslation';
import { MESSAGE_TOAST_TIMEOUT } from '@configs';

export const useRichmenuChannelContent = () => {
  const machineService = useRichmenuChannel();
  const [state] = useActor(machineService.richmenuChannelService);
  const { send } = machineService.richmenuChannelService;
  const [height, setHeight] = useState<number>();
  const alert = useAlert();
  const t = useTranslation();

  useEffect(() => {
    setHeight(window.innerHeight - 225);
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const updateHeight = () => {
    setHeight(window.innerHeight - 225);
  };

  useEffect(() => {
    if (
      state.matches('deleteRichmenuModal.deleteActiveRichmenuSuccess') ||
      state.matches('deleteRichmenuModal.deleteUpcomingRichmenuSuccess')
    ) {
      alert.success(t('richmenu.channel.list.delete.success'), {
        timeout: MESSAGE_TOAST_TIMEOUT,
      });

      return;
    }
  }, [state]);

  return {
    height,
    context: state.context,
    isShowErrorModal:
      state.matches('fetchActiveRichmenuListFailed') ||
      state.matches('fetchUpcomingRichmenuListFailed') ||
      state.matches('fetchNextActiveRichmenuListFailed') ||
      state.matches('fetchNextUpcomingRichmenuListFailed') ||
      state.matches('deleteRichmenuModal.deleteActiveRichmenuFailed') ||
      state.matches('deleteRichmenuModal.deleteUpcomingRichmenuFailed') ||
      state.matches('fetchChannelAndActiveListFailed'),
    isLoading: state.matches('fetchingChannelAndActiveList'),
    isFetchingActiveRichmenuList: state.matches('fetchingActiveRichmenuList'),
    isFetchingUpcomingRichmenuList: state.matches('fetchingUpcomingRichmenuList'),
    isShowDeleteRichmenu: state.matches('deleteRichmenuModal'),
    isDeletingRichmenu: state.matches('deleteRichmenuModal.deletingActiveRichmenu'),
    onFetchActiveList: () => send('FETCH_ACTIVE_RICHMENU'),
    onFetchUpcomingList: () => send('FETCH_UPCOMING_RICHMENU'),
    onFetchNextActiveList: () => send('FETCH_NEXT_ACTIVE_RICHMENU'),
    onFetchNextUpcomingList: () => send('FETCH_NEXT_UPCOMING_RICHMENU'),
    onIdle: () => send('IDLE'),
    onConfirmDelete: (id: string, richmenuChannelType: IRichmenuSettingType) => {
      send('DELETE_RICHMENU_MODAL', { id, richmenuChannelType });
    },
    onDelete: () => send('DELETE_RICHMENU'),
    onCancelDelete: () => send('CANCEL_DELETE_RICHMENU'),
  };
};
