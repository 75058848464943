import React, { createContext, useContext } from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { richmenuChannelMachine } from './machine';
import { useParams } from 'react-router-dom';
import { RichmenuChannelContent } from './RichmenuChannelContent';

export const RichmenuChannelContext = createContext({
  richmenuChannelService: {} as InterpreterFrom<typeof richmenuChannelMachine>,
});

export const useRichmenuChannel = () => useContext(RichmenuChannelContext);

const RichmenuChannel: React.FC = () => {
  const { id } = useParams();
  const richmenuChannelService = useInterpret(richmenuChannelMachine(id as string), {
    devTools: import.meta.env.MODE === 'development' ? true : false,
  });

  return (
    <RichmenuChannelContext.Provider value={{ richmenuChannelService }}>
      <RichmenuChannelContent />
    </RichmenuChannelContext.Provider>
  );
};

export default RichmenuChannel;
