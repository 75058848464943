import React from 'react';
import {
  CustomModal,
  Container,
  CustomIcon,
  Title,
  Description,
  ConfirmBtn,
} from './style';
export type IAlertModal = {
  title: string;
  description: string;
  btnTxt: string;
  visible: boolean;
  closable?: boolean;
  onClick: () => void;
  onClose?: () => void;
};

const Z_INDEX = 1031; //1031 is default z-index of antd modal and another component like popover so I want to give it above the popover

export const CustomAlertModal: React.FC<IAlertModal> = ({
  title,
  description,
  btnTxt = 'OK',
  visible,
  closable = false,
  onClick,
  onClose,
}) => {
  return (
    <CustomModal
      data-testid="test-custom-alert-modal"
      visible={visible}
      zIndex={Z_INDEX}
      closable={closable}
      onCancel={onClose}
      destroyOnClose
      centered
    >
      <Container>
        <CustomIcon icon={['far', 'exclamation-circle']} />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ConfirmBtn onClick={onClick} size="large">
          {btnTxt}
        </ConfirmBtn>
      </Container>
    </CustomModal>
  );
};
