import { PATH } from '@configs';

export const redirectTo = (pathName: string) => {
  if (
    [
      PATH.LOGIN,
      PATH.RESET_PASSWORD,
      PATH.CHANGE_PASSWORD,
      PATH.REQUEST_RESET_PASSWORD,
    ].includes(pathName)
  ) {
    return '/';
  }
  return pathName;
};
