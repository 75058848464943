import { TOTAL_MESSAGE_NUMBER_FILTER } from '@configs';
import { ITotalMessageFilterNumber } from '@types';
import { useState } from 'react';

export const useMessageInfoNumber = () => {
  const [selectedFilter, setSelectedFilter] = useState<ITotalMessageFilterNumber[]>(
    TOTAL_MESSAGE_NUMBER_FILTER,
  );
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  return {
    selectedFilter,
    visibleDropdown,
    onToggleDropdown: () => {
      setVisibleDropdown(!visibleDropdown);
    },
    onSelect: (data: ITotalMessageFilterNumber) => {
      // find index of selectedFilter
      const index = selectedFilter.findIndex((item) => item.value === data.value);
      // if index === -1 => push data to selectedFilter
      if (index === -1) {
        setSelectedFilter([...selectedFilter, data]);
      }
      // else => remove data from selectedFilter
      else {
        const newSelectedFilter = selectedFilter.filter(
          (item) => item.value !== data.value,
        );
        setSelectedFilter(newSelectedFilter);
      }
    },
  };
};
