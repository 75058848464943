import styled from 'styled-components/macro';
import { DeleteModal as DianaDeleteModal } from '@amityco/diana-bot';

export const Container = styled.div`
  margin: auto;
  width: 768px;
`;

export const Inner = styled.div`
  margin-top: 38px;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

export const ListContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 225px);
`;

export const TabKey = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  user-select: none;
  span:first-child {
    margin-right: 4px;
  }
`;

export const RichmenuCount = styled.span`
  background-color: ${({ theme }) => theme.palette?.PRIMARY};
  margin-left: 4px;
  color: white;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 4px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyState = styled.div`
  width: 100%;
  text-align: center;
  span {
    display: inline-block;
    margin-top: 80px;
  }
`;

export const DeleteModal = styled(DianaDeleteModal)`
  .ant-modal-body > * {
    width: 100% !important;
  }
  .ant-modal-body > span:first-child {
    margin-bottom: 8px;
  }
`;
