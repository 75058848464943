import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, SecondaryFrame, IconFrame, TitleFrame } from './style';

export type IErrorStateProps = {
  icon?: IconProp;
  img?: string;
  title: string;
  description: string;
  errorCode?: string;
  children?: React.ReactNode;
};

export const ErrorState: React.FC<IErrorStateProps> = ({
  icon,
  title,
  img,
  description,
  errorCode,
  children,
}) => {
  const _renderIcon = () => {
    if (img) {
      return <img src={img} />;
    }

    if (icon) {
      return <FontAwesomeIcon icon={icon} />;
    }
    return null;
  };

  return (
    <Container data-testid="test-error-state">
      <IconFrame>{_renderIcon()}</IconFrame>
      {title ? (
        <TitleFrame>
          <h3>{title}</h3>
        </TitleFrame>
      ) : null}
      {description ? (
        <SecondaryFrame>
          <p>{description}</p>
        </SecondaryFrame>
      ) : null}
      {errorCode ? (
        <SecondaryFrame>
          <p>{errorCode}</p>
        </SecondaryFrame>
      ) : null}
      {children}
    </Container>
  );
};
