import { ERROR_TITLE_DEAULT_MESSAGE } from '@configs';
import { EXCEPTIONS_MAP, EXCEPTIONS_PRE_AUTH_PREFIX } from '../constants';
export type IAmplifyException = {
  code: string;
  name: string;
  message: string;
};

export const exceptionMapper = (error: IAmplifyException) => {
  if (EXCEPTIONS_MAP[error.message]) {
    return EXCEPTIONS_MAP[error.message];
  }

  if (error.message.includes(EXCEPTIONS_PRE_AUTH_PREFIX)) {
    return error.message.replace(EXCEPTIONS_PRE_AUTH_PREFIX, '');
  }

  if (error.message) {
    return error.message;
  }

  return ERROR_TITLE_DEAULT_MESSAGE;
};
