import { useState } from 'react';
import { getRole } from '@api';
import { ERROR_NO_PERMISSION, PATH } from '@configs';
import { useAuth } from '@providers/AuthProvider';
import { useQuery } from '@tanstack/react-query';
import { ICognitoUser2, IMember } from '@types';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';

const enum EMemberInfoResponseIndex {
  MEMBER = 0,
  ROLE = 1,
}

export type IMemberContext = {
  member: IMember | null;
  isCimAdmin: boolean;
  isFetchingMember: boolean;
  onClearMember: () => void;
};

export const useMember = () => {
  const [member, setMember] = useState<IMemberContext['member']>(null);
  const { cognitoUser, onGetAttributes, onLogout } = useAuth();
  const navigate = useNavigate();

  const getMemberInfo = () => {
    if (cognitoUser) {
      return Promise.all([getMember(cognitoUser), getRole()]);
    }
  };

  const { isFetching: isFetchingMember } = useQuery(['memberInfo'], getMemberInfo, {
    retry: 0,
    enabled: !!cognitoUser,
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.error(error);
      navigate(PATH.ERROR);
    },
    onSuccess: (data) => {
      if (data) {
        const memberData = data[EMemberInfoResponseIndex.MEMBER];
        const role = data[EMemberInfoResponseIndex.ROLE];

        if (isEmpty(role.products)) {
          navigate(PATH.ERROR, {
            state: { message: ERROR_NO_PERMISSION },
          });
          return;
        }
        if (memberData && role) {
          setMember(() => {
            return {
              ...memberData,
              role: role,
              attributes: {
                ...memberData.attributes,
                roles: memberData.attributes['custom:roles'].split(','),
              },
            };
          });
        }
      }
    },
  });

  const getMember = async (cognitoUser: ICognitoUser2) => {
    try {
      const result = await onGetAttributes(cognitoUser);
      const memberAttributes: { [key: string]: any } = {};
      if (result) {
        result.forEach((item) => {
          memberAttributes[item.Name] = item.Value;
        });
      }
      return {
        username: cognitoUser.username,
        attributes: memberAttributes,
      };
    } catch (error) {
      onLogout();
      console.error(error);
    }
  };

  const isCimAdmin = member?.attributes.roles.includes('cim.admin') || false;

  return { member, isFetchingMember, isCimAdmin, onClearMember: () => setMember(null) };
};
