import styled, { DefaultTheme } from 'styled-components/macro';

export const Container = styled.div<{ isStartDate: boolean }>`
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: ${({ isStartDate }) => (isStartDate ? 0 : '16px')};
`;

export const DayOfWeekWrap = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 272px;
  user-select: none;
`;

export const DateText = styled.div<{ isSelected?: boolean }>`
  position: relative;
  padding-top: 16px;
  cursor: pointer;
  user-select: none;
  span {
    ${({ isSelected }) => isSelected && `color: white`};
  }
`;

export const DateTextWrap = styled.div<{ isDisabled: boolean }>`
  position: relative;
  z-index: 3;
  span {
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.palette?.GREY_6} `};
  }
`;

export const SelectedCircle = styled.div<{ isToday: boolean; isSelected: boolean }>`
  position: absolute;
  top: 10px;
  z-index: 2;
  ${({ isToday, isSelected, theme }) => renderCircle({ isToday, isSelected, theme })};
`;

export const DateOfMonthWrap = styled.div`
  display: flex;
  /* column-gap: 8px; */
  flex-wrap: wrap;
  width: 272px;
`;

const renderCircle = ({
  isToday,
  isSelected,
  theme,
}: {
  isToday: boolean;
  isSelected: boolean;
  theme: DefaultTheme;
}) => {
  if (isSelected) {
    return `
      background-color: ${theme.palette?.PRIMARY};
      border-radius: 50%;
      width: 32px;
      height: 32px;
    `;
  }
  if (isToday) {
    return `
      border:1px solid ${theme.palette?.PRIMARY};
      border-radius: 50%;
      width: 32px;
      height: 32px;
    `;
  }
};

export const InRangeDate = styled.div`
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: #e6f9f3;
`;

export const SelectTime = styled.button`
  border: 1px solid ${({ theme }) => theme.palette?.GREY_7};
  border-radius: 8px;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  margin-top: 8px;
  height: 36px;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.palette?.GREY_6};
  }
`;

export const TimeItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: ${({ isSelected, theme }) => isSelected && theme.palette?.GREY_9};
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.palette?.GREY_9};
  }
`;

export const DisabledTimeItem = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${({ theme }) => theme.palette?.GREY_6};
  cursor: not-allowed;
`;

export const SelectedTimeInner = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;
