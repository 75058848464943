import styled from 'styled-components/macro';

export const Container = styled.div``;

export const ListItem = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  padding: 10px 16px;
  cursor: pointer;
`;
