import { Tab as DianaTab } from '@amityco/diana-bot';
import styled from 'styled-components';

export const Tab = styled(DianaTab)`
  font-weight: 500;
  font-size: 14px;
  .ant-tabs-tab {
    color: ${({ theme }) => theme.palette?.GREY_5};
  }

  .ant-tabs-tab-active {
    color: ${({ theme }) => theme.palette?.GREY_1};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none;
  }
`;
