import { TertiaryButton } from '@amityco/diana-bot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { BlankModal } from '@amityco/diana-bot';

export const CustomModal = styled(BlankModal)`
  .ant-modal-content {
    margin: auto;
    height: max-content;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
`;

export const CustomIcon = styled(FontAwesomeIcon)`
  width: 60px;
  height: 60px;
  color: #bdbdbd;
`;

export const ConfirmBtn = styled(TertiaryButton)`
  min-width: 72px;
  margin-top: 24px;
`;

export const Description = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette?.GREY_3};
  text-align: center;
  width: 296px;
  word-break: break-word;
  white-space: pre-line;
`;

export const Title = styled.span`
  margin-top: 18px;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.palette?.GREY_1};
  line-height: 32px;
`;
