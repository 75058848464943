import React from 'react';
import ReactDOM from 'react-dom';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import * as font from './iconPack';
import App from './App';
import './styles/style.css';

library.add(font as IconPack);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
