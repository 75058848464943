import {
  ERICHMENU_SETTING_ADD_TIME,
  ERICHMENU_SETTING_MENU_BEHAVIOR,
  ERICHMENU_SETTING_TARGET_AUDIENCE,
} from '@configs';
import { IRichmenuChannelItemBE, IRichmenuChannelItemFE } from '@types';
import { formatDate } from 'utils';

export const transformActiveRichmenuChannelItemToFE = (
  richmenuItem: IRichmenuChannelItemBE,
): IRichmenuChannelItemFE => {
  return {
    ...richmenuItem,
    menuBarLabel: richmenuItem.chatBarText,
    menuBehavior: richmenuItem.selected
      ? ERICHMENU_SETTING_MENU_BEHAVIOR.AUTO_DISPLAYED
      : ERICHMENU_SETTING_MENU_BEHAVIOR.COLLAPSED,
    targetAudience: richmenuItem.default
      ? ERICHMENU_SETTING_TARGET_AUDIENCE.ALL
      : ERICHMENU_SETTING_TARGET_AUDIENCE.SEGMENT,
    image: richmenuItem.richmenu.image,
    dateToAddStr: formatDate(richmenuItem.createdAt, 'DD MMM YYYY | HH:mm') || '',
    dateToAdd: ERICHMENU_SETTING_ADD_TIME.NOW,
    priority: richmenuItem.priority ? richmenuItem.priority.toString() : '',
  } as unknown as IRichmenuChannelItemFE;
};

export const transformUpcomingRichmenuChannelItemToFE = (
  richmenuItem: IRichmenuChannelItemBE,
): IRichmenuChannelItemFE => {
  return {
    ...richmenuItem,
    menuBarLabel: richmenuItem.chatBarText,
    menuBehavior: richmenuItem.selected
      ? ERICHMENU_SETTING_MENU_BEHAVIOR.AUTO_DISPLAYED
      : ERICHMENU_SETTING_MENU_BEHAVIOR.COLLAPSED,
    targetAudience: richmenuItem.default
      ? ERICHMENU_SETTING_TARGET_AUDIENCE.ALL
      : ERICHMENU_SETTING_TARGET_AUDIENCE.SEGMENT,
    image: richmenuItem.richmenu.image,
    dateToAddStr: formatDate(richmenuItem.triggerTime, 'DD MMM YYYY | HH:mm') || '',
    dateToAdd: ERICHMENU_SETTING_ADD_TIME.CUSTOM,
    priority: richmenuItem.priority ? richmenuItem.priority.toString() : '',
  } as unknown as IRichmenuChannelItemFE;
};
