import { CIM_API_ENDPOINT, client } from '@api';
import {
  IActiveUserChartResponse,
  IActiveUserCountResponse,
  IAvgMessageResponse,
  IFetchTemplateRequest,
  IMaxMessageResponse,
  INewUserCountResponse,
  ITemplateApi,
  ITotalBotCountResponse,
  ITotalMessageChartResponse,
  ITotalMessageCountResponse,
  IUserJourneyResponse,
  IUserJourneySegmentResponse,
  ITopUserResponse,
} from '@types';

export const fetchAvgMessageCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: IAvgMessageResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/count/average?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchMaxMessageCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: IMaxMessageResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/count/max?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchMessageCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: ITotalMessageCountResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/count/total?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchBotCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: ITotalBotCountResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/count/bot?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchTotalMessageChart = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: ITotalMessageChartResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/chart?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchActiveUserChart = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: IActiveUserChartResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/users/active/chart?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchActiveUserCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: IActiveUserCountResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/users/active/count/total?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchNewUserCount = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: INewUserCountResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/users/new/count/total?${filter}`,
    {
      signal,
    },
  );
  return data;
};

// user journey
export const fetchUserJourney = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: IUserJourneyResponse } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/user-journeys?${filter}`,
    {
      signal,
    },
  );
  return data;
};

export const fetchCimTemplate = async (params?: IFetchTemplateRequest) => {
  const { data }: { data: ITemplateApi[] } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/user-journeys/templates`,
    {
      params: {
        take: params?.take,
        cursorId: params?.cursorId,
        keyword: params?.keyword ? params.keyword : undefined,
        skip: params?.skip,
      },
    },
  );
  return data;
};

export const fetchUserJourneySegment = async (params: string, signal?: AbortSignal) => {
  const { data }: { data: IUserJourneySegmentResponse[] } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/user-journeys/segments?${params}`,
    {
      signal,
    },
  );
  return data;
};

// top user
export const fetchTopUser = async (filter: string, signal?: AbortSignal) => {
  const { data }: { data: ITopUserResponse[] } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/leaderboard/top-n-user-sent-message?${filter}`,
    {
      signal,
    },
  );
  return data;
};

// heatmap
export const fetchHeatmap = async (filter: string, signal?: AbortSignal) => {
  const { data } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/dashboard/messages/chart/heat-map?${filter}`,
    {
      signal,
    },
  );
  return data;
};
