import { Percent } from '@components/Percent';
import { Font14G5W400, Font16G1W600, Font20G1W600 } from '@components/UtilitiesComponent';
import React from 'react';

type INumListItemProps = {
  no: number;
  title: string;
  description: string;
  percentChange: number;
};

export const NumListItem = ({
  no,
  title,
  description,
  percentChange,
}: INumListItemProps) => {
  return (
    <div className="flex gap-x-[12px] items-center">
      <Font20G1W600 className="italic">#{no}</Font20G1W600>
      <div
        className="whitespace-nowrap w-full overflow-hidden text-ellipsis"
        title={title}
      >
        <Font16G1W600>{title}</Font16G1W600>
      </div>
      {/* className="!text-[#0F86FE]" */}
      <Font14G5W400>{description}</Font14G5W400>
      <Percent num={percentChange} />
    </div>
  );
};
