import React from 'react';
import { Checkbox } from '@amityco/diana-bot';
import { Font14G1W400 } from '@components/UtilitiesComponent';
import { ListItem } from './style';

export type ISelectListOptions = {
  label: string;
  value: string;
  [key: string]: any;
};

type ISelectListProps<T extends ISelectListOptions> = {
  selected: T[];
  options: T[];
  onClick: (item: T) => void;
};

export const SelectList = <T extends ISelectListOptions>({
  selected,
  options,
  onClick,
}: ISelectListProps<T>) => {
  return (
    <>
      {options.map((item) => {
        const checked = selected.some((selectedItem) => {
          return selectedItem.value === item.value;
        });
        return (
          <ListItem key={item.value} onClick={() => onClick(item)}>
            <Font14G1W400>{item.label}</Font14G1W400>
            <Checkbox checked={checked} />
          </ListItem>
        );
      })}
    </>
  );
};
