import React from 'react';
import { IChannelApiResponse } from '@types';

export const selectChannel = <SetStateType extends { channels: IChannelApiResponse[] }>(
  originalChannel: IChannelApiResponse[],
  payload: IChannelApiResponse,
  setter: React.Dispatch<React.SetStateAction<SetStateType>>,
) => {
  // find index of channel in channels
  const index = originalChannel.findIndex((item) => item.id === payload.id);
  // if index === -1 => not found push
  if (index === -1) {
    setter((prev) => ({ ...prev, channels: [...prev.channels, payload] }));
  }
  // else remove
  else {
    setter((prev) => ({
      ...prev,
      channels: prev.channels.filter((item) => item.id !== payload.id),
    }));
  }
};

export const clearChannel = <SetStateType extends { channels: IChannelApiResponse[] }>(
  originalChannel: IChannelApiResponse[],
  setter: React.Dispatch<React.SetStateAction<SetStateType>>,
) => {
  // if channels is empty => return
  if (originalChannel.length === 0) return;
  setter((prev) => ({ ...prev, channels: [] }));
};
