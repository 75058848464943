import { ESegmentType } from '@configs';

export type IFromToCount = { [key: string]: number };

// User Journey
export const enum EUSER_JOURNEY_SOURCE {
  NONE = 'none',
  TEMPLATE = 'template',
  MAPPER = 'mapper',
  RICHMENU = 'richmenu',
}

export type IFromTo = {
  journeyButton: string;
  count: number;
  currentButton: string;
};

export type IUserJourneyResponse = {
  previous: IFromTo[];
  next: IFromTo[];
};

export type IUserJourneySankeyData = {
  color: string;
  from: string;
  to: string;
  weight: number;
};

export type IUserJourneyFilterRequestParams = {
  startIsoStringDate: string;
  endIsoStringDate: string;
  unitId: string;
  channels?: string[];
  segmentIds?: string[];
};

export type IUserJourneySegmentResponse = {
  id: string;
  name: string;
  type: ESegmentType;
  networkId: string;
  channelId: string;
  isDeleted: true;
  deletedAt: string;
};

export type IUserJourneySegmentParams = {
  channels?: string;
};
