import { PATH } from '@configs/routes';
import { uniqueId } from 'lodash';

export const BASE_URL = import.meta.env.BASE_URL ?? '/';

export const BYTE_PER_KB = 1024;

export const ALERT_TIMEOUT = 2000;

export const COMMON_MAX_TAKE = 10;

export const enum MESSAGE_TYPE {
  TEXT = 'text',
  CAROUSEL = 'carousel',
  IMAGEMAP = 'imagemap',
  OPTIONS = 'buttons',
  IMAGE_CAROUSEL = 'image_carousel',
  FLEX = 'flex',
  IMAGE = 'image',
  VIDEO = 'video',
}

export const enum MESSAGE_ACTION_TYPE {
  MESSAGE = 'message',
  CALL = 'call_action',
  URI = 'uri',
  CAMERA = 'camera',
  TEMPLATE = 'template_action',
  DATE_PICKER = 'datetimepicker',
  FLOW = 'flow_action',
  LOCATION = 'location',
  ASSIGN_TICKET = 'assign_ticket',
}

export const enum VALIDATE_MESSAGE {
  TEXT = 'Text is required',
  URL = 'URL is required',
  COMPLETENESS = 'Please check completeness',
  ACTION = 'Action value is required',
  IMAGE_AND_ACTION = 'images and actions is required',
  IMAGE_MAP_NOT_COMPLETE = 'Please complete the imagemap before saving.',
  EMPTY_IMAGE_MAP_ACTION = 'At least one action is required',
}

export const IMPORT_MODAL_WIDTH_PX = 625;

export const CALENDAR_DATE_FORMAT = 'DD MMM YYYY';
export const TIME_INPUT_FORMAT = 'HH : mm';

export const SHORT_CUTS = [
  {
    id: uniqueId(),
    to: PATH.CHATBOT_INTENT,
    label: 'Intent',
  },
  {
    id: uniqueId(),
    to: PATH.CHATBOT_ENTITY,
    label: 'Entity',
  },
  {
    id: uniqueId(),
    to: PATH.CHATBOT_MAPPER,
    label: 'Mapper',
  },
];

export const NO_RESULT_TITLE = 'No result';
export const NO_RESULT_DESCRIPTION = '';
