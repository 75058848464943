import {
  deleteActiveRichmenu,
  deleteUpcomingRichmenu,
  fetchActiveRichmenuList,
  fetchRichmenuChannelByChannelId,
  fetchUpcomingRichmenuList,
} from '@api';
import { ERICHMENU_SETTING_TYPE, RICHMENU_API_MAX_FETCH_TAKE } from '@configs';
import {
  IRichmenuChannelItemBE,
  IRichmenuChannelItemFE,
  IRichmenuSettingType,
} from '@types';
import { createMachine, assign, AnyEventObject } from 'xstate';
import { send } from 'xstate/lib/actions';
import {
  transformActiveRichmenuChannelItemToFE,
  transformUpcomingRichmenuChannelItemToFE,
} from '../fns';

type IRichmenuChannelMachineContext = {
  channelId: string;
  channelName: string;
  richmenuCannelToDelete: {
    id: string;
    type: IRichmenuSettingType;
  };
  count: {
    activeRichmenu?: number;
    upcomingRichmenu?: number;
  };
  activeRichmenuList: {
    items: IRichmenuChannelItemFE[];
    nextCursorId: string;
    hasMore: boolean;
  };
  upcomingRichmenuList: {
    items: IRichmenuChannelItemFE[];
    nextCursorId: string;
    hasMore: boolean;
  };
  errorMessage: {
    title: string;
    description: string;
  };
};

export const richmenuChannelMachine = (id: string) => {
  return createMachine(
    {
      id: 'richmenu-channel-list',
      initial: 'fetchingChannelAndActiveList',
      states: {
        idle: {},
        fetchingActiveRichmenuList: {
          invoke: {
            id: 'fetchActiveRichmenuList',
            src: 'fetchActiveRichmenuList',
            onDone: [
              {
                target: 'fetchActiveRichmenuListSuccess',
                actions: 'updateActiveRichmenuList',
              },
            ],
            onError: [
              {
                target: 'fetchActiveRichmenuListFailed',
              },
            ],
          },
        },
        fetchingUpcomingRichmenuList: {
          invoke: {
            id: 'fetchUpcomingRichmenuList',
            src: 'fetchUpcomingRichmenuList',
            onDone: [
              {
                target: 'fetchUpcomingRichmenuListSuccess',
                actions: 'updateUpcomingRichmenuList',
              },
            ],
            onError: [
              {
                target: 'fetchUpcomingRichmenuListFailed',
              },
            ],
          },
        },
        fetchActiveRichmenuListSuccess: {},
        fetchActiveRichmenuListFailed: {},
        fetchUpcomingRichmenuListSuccess: {},
        fetchUpcomingRichmenuListFailed: {},
        fetchingNextActiveRichmenuList: {
          invoke: {
            id: 'fetchNextActiveRichmenuList',
            src: 'fetchNextActiveRichmenuList',
            onDone: {
              target: 'fetchNextActiveRichmenuListSuccess',
              actions: 'updateNextActiveRichmenuList',
            },
            onError: {
              target: 'fetchNextActiveRichmenuListFailed',
            },
          },
        },
        fetchNextActiveRichmenuListSuccess: {},
        fetchNextActiveRichmenuListFailed: {},
        fetchingNextUpcomingRichmenuList: {
          invoke: {
            id: 'fetchNextUpcomingRichmenuList',
            src: 'fetchNextUpcomingRichmenuList',
            onDone: {
              target: 'fetchNextUpcomingRichmenuListSuccess',
              actions: 'updateNextUpcomingRichmenuList',
            },
            onError: {
              target: 'fetchNextUpcomingRichmenuListFailed',
            },
          },
        },
        fetchNextUpcomingRichmenuListSuccess: {},
        fetchNextUpcomingRichmenuListFailed: {},
        deleteRichmenuModal: {
          initial: 'idle',
          states: {
            idle: {},
            deletingActiveRichmenu: {
              invoke: {
                id: 'deleteActiveRichmenu',
                src: 'deleteActiveRichmenu',
                onDone: {
                  target: 'deleteActiveRichmenuSuccess',
                },
                onError: {
                  target: 'deleteActiveRichmenuFailed',
                },
              },
            },
            deleteActiveRichmenuSuccess: {
              entry: send('FETCH_ACTIVE_RICHMENU'),
            },
            deleteActiveRichmenuFailed: {},
            deletingUpcomingRichmenu: {
              invoke: {
                id: 'deleteUpcomingRichmenu',
                src: 'deleteUpcomingRichmenu',
                onDone: {
                  target: 'deleteUpcomingRichmenuSuccess',
                },
                onError: {
                  target: 'deleteUpcomingRichmenuFailed',
                },
              },
            },
            deleteUpcomingRichmenuSuccess: {
              entry: send('FETCH_UPCOMING_RICHMENU'),
            },
            deleteUpcomingRichmenuFailed: {},
          },
          on: {
            IDLE: {
              target: '.idle',
            },
          },
        },
        fetchingChannelAndActiveList: {
          invoke: {
            id: 'fetchChannelAndActiveList',
            src: 'fetchChannelAndActiveList',

            onDone: {
              target: 'fetchChannelAndActiveListSuccess',
              actions: 'updateChannelAndActiveList',
            },
            onError: {
              target: 'fetchChannelAndActiveListFailed',
            },
          },
        },
        fetchChannelAndActiveListSuccess: {},
        fetchChannelAndActiveListFailed: {},
      },
      on: {
        RE_FETCH_CHANNEL_AND_ACTIVE_RICHMENU: {
          target: 'fetchingChannelAndActiveList',
        },
        FETCH_ACTIVE_RICHMENU: {
          target: 'fetchingActiveRichmenuList',
        },
        FETCH_UPCOMING_RICHMENU: {
          target: 'fetchingUpcomingRichmenuList',
        },
        IDLE: {
          target: 'idle',
        },
        FETCH_NEXT_ACTIVE_RICHMENU: {
          target: 'fetchingNextActiveRichmenuList',
        },
        FETCH_NEXT_UPCOMING_RICHMENU: {
          target: 'fetchingNextUpcomingRichmenuList',
        },
        DELETE_RICHMENU_MODAL: {
          target: 'deleteRichmenuModal',
          actions: 'updateRichmenuChannelIdToDelete',
        },
        CANCEL_DELETE_RICHMENU: {
          target: 'idle',
        },
        DELETE_RICHMENU: [
          {
            cond: (context: IRichmenuChannelMachineContext) =>
              context.richmenuCannelToDelete.type === ERICHMENU_SETTING_TYPE.ACTIVE,
            target: 'deleteRichmenuModal.deletingActiveRichmenu',
          },
          {
            cond: (context: IRichmenuChannelMachineContext) =>
              context.richmenuCannelToDelete.type === ERICHMENU_SETTING_TYPE.UPCOMING,
            target: 'deleteRichmenuModal.deletingUpcomingRichmenu',
          },
        ],
      },
      schema: {
        context: {} as IRichmenuChannelMachineContext,
        events: {} as
          | { type: 'FETCH_ACTIVE_RICHMENU' }
          | { type: 'FETCH_UPCOMING_RICHMENU' }
          | { type: 'IDLE' }
          | { type: 'FETCH_NEXT_ACTIVE_RICHMENU' }
          | { type: 'FETCH_NEXT_UPCOMING_RICHMENU' }
          | { type: 'DELETE_RICHMENU_MODAL' }
          | { type: 'CANCEL_DELETE_RICHMENU' }
          | { type: 'DELETE_RICHMENU' }
          | AnyEventObject,
      },
      preserveActionOrder: true,
      context: {
        channelId: id,
        channelName: 'Channel',
        count: {},
        richmenuCannelToDelete: {
          id: '',
          type: 'active',
        },
        activeRichmenuList: {
          items: [],
          nextCursorId: '',
          hasMore: true,
        },
        upcomingRichmenuList: {
          items: [],
          nextCursorId: '',
          hasMore: true,
        },
        errorMessage: {
          title: '',
          description: '',
        },
      },
    },
    {
      actions: {
        updateActiveRichmenuList: assign({
          activeRichmenuList: (_, event: AnyEventObject) => {
            const { data } = event;
            const newRichmenuList = {
              ...data,
              items: data.items.map((item: IRichmenuChannelItemBE) =>
                transformActiveRichmenuChannelItemToFE(item),
              ),
            };
            return { ...newRichmenuList };
          },
          count: (_, event: AnyEventObject) => {
            const { data } = event;
            return {
              activeRichmenu: data?.channelData?._count?.activeRichmenu || 0,
              upcomingRichmenu: data?.channelData?._count?.upcomingRichmenu || 0,
            };
          },
        }),
        updateUpcomingRichmenuList: assign({
          upcomingRichmenuList: (_, event: AnyEventObject) => {
            const { data } = event;
            const newRichmenuList = {
              ...data,
              items: data.items.map((item: IRichmenuChannelItemBE) =>
                transformUpcomingRichmenuChannelItemToFE(item),
              ),
            };

            return { ...newRichmenuList };
          },
          count: (_, event: AnyEventObject) => {
            const { data } = event;
            return {
              activeRichmenu: data?.channelData?._count?.activeRichmenu || 0,
              upcomingRichmenu: data?.channelData?._count?.upcomingRichmenu || 0,
            };
          },
        }),
        updateNextActiveRichmenuList: assign({
          activeRichmenuList: (context, event: AnyEventObject) => {
            const { data } = event;

            const newRichmenuList = {
              ...data,
              items: [
                ...context.activeRichmenuList.items,
                ...data.items.map((item: IRichmenuChannelItemBE) =>
                  transformActiveRichmenuChannelItemToFE(item),
                ),
              ],
            };
            return { ...newRichmenuList };
          },
        }),
        updateNextUpcomingRichmenuList: assign({
          upcomingRichmenuList: (context, event: AnyEventObject) => {
            const { data } = event;
            const newRichmenuList = {
              ...data,
              items: [
                ...context.upcomingRichmenuList.items,
                ...data.items.map((item: IRichmenuChannelItemBE) =>
                  transformUpcomingRichmenuChannelItemToFE(item),
                ),
              ],
            };
            return { ...newRichmenuList };
          },
        }),
        updateRichmenuChannelIdToDelete: assign({
          richmenuCannelToDelete: (context, event: AnyEventObject) => {
            const { id, richmenuChannelType } = event;
            context.richmenuCannelToDelete = {
              ...context.richmenuCannelToDelete,
              id,
              type: richmenuChannelType as IRichmenuSettingType,
            };
            return {
              ...context.richmenuCannelToDelete,
            };
          },
        }),
        updateCount: assign({
          count: (context, event: AnyEventObject) => {
            const { data } = event;
            return {
              ...context.count,
              ...data,
            };
          },
        }),
        updateChannelAndActiveList: assign((context, event: AnyEventObject) => {
          const { data } = event;
          const channelResult = data[0].data;

          // update active list
          const activeRichmenuListResult = data[1].data;
          let itemPop = null;
          if (activeRichmenuListResult.length > RICHMENU_API_MAX_FETCH_TAKE) {
            itemPop = activeRichmenuListResult.pop();
          }

          return {
            ...context,
            channelName: channelResult.name,
            count: {
              ...context.count,
              ...channelResult._count,
            },
            activeRichmenuList: {
              items: activeRichmenuListResult.map((item: IRichmenuChannelItemBE) =>
                transformActiveRichmenuChannelItemToFE(item),
              ),
              nextCursorId:
                activeRichmenuListResult.length > 0
                  ? activeRichmenuListResult[activeRichmenuListResult.length - 1].id
                  : '',
              hasMore: !!itemPop,
            },
            upcomingRichmenuList: {
              ...context.upcomingRichmenuList,
              count: channelResult._count.upcomingRichmenu,
            },
          };
        }),
      },
      services: {
        fetchActiveRichmenuList: async (context) => {
          const { data: channelData } = await fetchRichmenuChannelByChannelId(
            context.channelId,
          );

          const { data: result } = await fetchActiveRichmenuList(context.channelId, {
            take: RICHMENU_API_MAX_FETCH_TAKE + 1,
          });

          let itemPop = null;

          if (result.length > RICHMENU_API_MAX_FETCH_TAKE) {
            itemPop = result.pop();
          }

          return {
            channelData: channelData,
            items: result,
            nextCursorId: result.length > 0 ? result[result.length - 1].id : '',
            hasMore: !!itemPop,
          };
        },
        fetchUpcomingRichmenuList: async (context) => {
          const { data: channelData } = await fetchRichmenuChannelByChannelId(
            context.channelId,
          );
          const { data: result } = await fetchUpcomingRichmenuList(context.channelId, {
            take: RICHMENU_API_MAX_FETCH_TAKE + 1,
          });

          let itemPop = null;

          if (result.length > RICHMENU_API_MAX_FETCH_TAKE) {
            itemPop = result.pop();
          }

          return {
            channelData: channelData,
            items: result,
            nextCursorId: result.length > 0 ? result[result.length - 1].id : '',
            hasMore: !!itemPop,
          };
        },
        fetchNextActiveRichmenuList: async (context, event: AnyEventObject) => {
          const { data: result } = await fetchActiveRichmenuList(context.channelId, {
            take: RICHMENU_API_MAX_FETCH_TAKE + 1,
            cursorId: context.activeRichmenuList.nextCursorId,
          });

          let itemPop = null;
          if (result.length > RICHMENU_API_MAX_FETCH_TAKE) {
            itemPop = result.pop();
          }

          return {
            items: result,
            nextCursorId: result.length > 0 ? result[result.length - 1].id : '',
            hasMore: !!itemPop,
          };
        },
        fetchNextUpcomingRichmenuList: async (context) => {
          const { data: result } = await fetchUpcomingRichmenuList(context.channelId, {
            take: RICHMENU_API_MAX_FETCH_TAKE + 1,
            cursorId: context.upcomingRichmenuList.nextCursorId,
          });

          let itemPop = null;
          if (result.length > RICHMENU_API_MAX_FETCH_TAKE) {
            itemPop = result.pop();
          }

          return {
            items: result,
            nextCursorId: result.length > 0 ? result[result.length - 1].id : '',
            hasMore: !!itemPop,
          };
        },
        deleteActiveRichmenu: async (context) => {
          return await deleteActiveRichmenu(
            context.channelId,
            context.richmenuCannelToDelete.id,
          );
        },
        deleteUpcomingRichmenu: async (context) => {
          return await deleteUpcomingRichmenu(
            context.channelId,
            context.richmenuCannelToDelete.id,
          );
        },
        fetchChannelAndActiveList: async (context) => {
          return await Promise.all([
            fetchRichmenuChannelByChannelId(context.channelId),
            fetchActiveRichmenuList(context.channelId, {
              take: RICHMENU_API_MAX_FETCH_TAKE + 1,
            }),
          ]);
        },
      },
    },
  );
};
