import React from 'react';
import { Chart } from '@components/Chart';
import { Card } from '@components/Card';
import { SeriesColumnOptions } from 'highcharts';
import { Container, ChartContainer, CardTitleExtra } from './style';
import { useTranslation } from '@hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faComments } from '@fortawesome/pro-solid-svg-icons';
import { FetchWrap } from '../FetchWrap';
import { useOverviewContext } from '../providers/OverviewProvider';
import { IFilterState } from '../useDashboard';
import {
  EWorkerMessageType,
  IChartData,
  ITotalMessageChartResponse,
  ITotalMessageChartRow,
  ITotalMessageFilterNumber,
} from '@types';
import { fetchTotalMessageChart } from '@api';
import { CardContent } from '../CardWrap';
import { ChartWrap } from '../ChartWrap';
import { Dropdown } from '@components/Dropdown';
import { useTotalMessageChart } from './useTotalMessageChart';
import { TOTAL_MESSAGE_NUMBER_FILTER_CHART } from '@configs';
import { SelectList } from '@components/SelectList';
import { generateFilterWithMessageSourcesRequest } from '../fns';

export const TotalMessageChart = () => {
  const { filter } = useOverviewContext();
  const { visibleDropdown, selectedFilter, onSelect, onToggleDropdown } =
    useTotalMessageChart();
  const t = useTranslation();

  return (
    <Container>
      <FetchWrap
        filter={filter}
        queryKey={['totalMessageChart', filter, selectedFilter]}
        fetchFn={async (
          filter: IFilterState,
          signal?: AbortSignal,
        ): Promise<ITotalMessageChartResponse> => {
          if (selectedFilter.length === 0) {
            return {
              rows: [],
            };
          }

          const transformFilter = generateFilterWithMessageSourcesRequest(
            filter,
            selectedFilter,
          );
          return fetchTotalMessageChart(transformFilter, signal);
        }}
        renderFn={({ isLoading, data, isError, refetch }) => {
          return (
            <Card
              title={t('dashboard.total.message.chart.title')}
              description={t('dashboard.total.message.chart.description')}
              divider
              titleExtra={
                <Dropdown
                  visible={visibleDropdown}
                  onVisibleChange={onToggleDropdown}
                  trigger={['click']}
                  overlayWidth={250}
                  overlayContent={
                    <SelectList<ITotalMessageFilterNumber>
                      selected={selectedFilter}
                      options={TOTAL_MESSAGE_NUMBER_FILTER_CHART}
                      onClick={onSelect}
                    />
                  }
                >
                  <CardTitleExtra onClick={onToggleDropdown}>
                    {selectedFilter.map((item) => {
                      return item.icon;
                    })}
                    <FontAwesomeIcon icon={faChevronDown} fontSize={8} />
                  </CardTitleExtra>
                </Dropdown>
              }
              topLeftIcon={<FontAwesomeIcon icon={faComments} fontSize={20} />}
            >
              <ChartContainer>
                <ChartWrap<ITotalMessageChartRow[], IChartData>
                  rows={data ? data.rows : []}
                  workerHandler={{
                    postMessage: (worker, rows) => {
                      worker.postMessage({
                        type: EWorkerMessageType.TOTAL_MESSAGE_CHART,
                        payload: {
                          rows: rows || [],
                          interval: filter.filterInterval,
                        },
                      });
                    },
                    modifier: (e, setter) => {
                      setter(e);
                    },
                  }}
                  renderFn={(chartData, error): JSX.Element => {
                    // selectedFilter.length = 0 display empty chart
                    let newDataSeries: IChartData = {
                      dataSeries: [],
                      dateInterval: [],
                    };
                    // no filter
                    // no raw data
                    // row is empty
                    // chartData is null
                    if (
                      selectedFilter.length === 0 ||
                      !data ||
                      data.rows.length === 0 ||
                      !chartData
                    ) {
                      newDataSeries = {
                        dataSeries: [
                          {
                            name: 'Series 1',
                            data: [],
                          },
                        ],
                        dateInterval: [],
                      };
                    } else {
                      newDataSeries = chartData;
                    }
                    return (
                      <CardContent
                        isLoading={isLoading}
                        isError={isError || error}
                        onRetry={() => {
                          if (isError) {
                            refetch();
                          }
                        }}
                      >
                        <Chart
                          options={{
                            title: {
                              text: '',
                            },
                            chart: {
                              type: 'area',
                              className: 'w-full',
                            },
                            legend: {
                              layout: 'horizontal',
                              align: 'center',
                              verticalAlign: 'top',
                              useHTML: true,
                              labelFormatter: function () {
                                const self = this as any;
                                const userOptions = self.userOptions as unknown as {
                                  data: number[];
                                  isDeleted: boolean;
                                  name: string;
                                };
                                if (userOptions.isDeleted) {
                                  return `
                                      ${self.name}
                                      <span style="color: #BEBEBE;">(removed)</span>
                                  `;
                                }
                                return self.name;
                              },
                            },
                            tooltip: {
                              useHTML: true,
                              shared: true,
                              outside: true,
                              formatter: function () {
                                const self = this as any;

                                return `
                                  <div class="block ml-[-8px] mr-[-8px] p-2" style="word-break: break-all; background: white;">${self.points
                                    ?.map((p: any) => {
                                      return `<div key={p.point.index} class="flex items-center gap-x-[4px] mb-1">
                                          <div class="block w-[10px] h-[10px] rounded-[100%]" style="background:${
                                            p.color
                                          };"></div>
                                          <span>${p.series.getName()} -> ${p.y}</span>
                                        </div>`;
                                    })
                                    .join('')}</div>
                                `;
                              },
                            },
                            yAxis: {
                              title: {
                                text: 'Messages',
                              },
                            },
                            plotOptions: {
                              area: {
                                stacking: 'normal',
                              },
                            },
                            xAxis: {
                              categories: newDataSeries.dateInterval,
                            },
                            series: newDataSeries.dataSeries as SeriesColumnOptions[],
                          }}
                        />
                      </CardContent>
                    );
                  }}
                />
              </ChartContainer>
            </Card>
          );
        }}
      />
    </Container>
  );
};
