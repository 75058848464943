import { TertiaryButton } from '@amityco/diana-bot';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReport } from '@hooks/useReport';
import { useTranslation } from '@hooks/useTranslation';
import { useOverviewContext } from '../../providers/OverviewProvider';
import React from 'react';
import { IOverviewGenReportParams } from '@types';
import { exportDashboardOverviewReport } from '@api';
import { EOverviewType, PATH } from '@configs';

export const OverviewReportBtn = () => {
  const t = useTranslation();
  const { filter } = useOverviewContext();

  const { exportMut } = useReport({
    exportFn: exportDashboardOverviewReport,
  });

  const onExport = async () => {
    try {
      const createFilter: IOverviewGenReportParams = {
        type: ['DASHBOARD_OVERVIEW'],
        filters: {
          channelIds:
            filter.channels.length > 0
              ? filter.channels.map((channel) => channel.channelId)
              : undefined,
          dateRange: {
            start: filter.startDate.toISOString(),
            end: filter.endDate.toISOString(),
          },
          interval: filter.filterInterval,
        },
      };
      const result = await exportMut.mutateAsync(createFilter);
      if (result) {
        window.open(
          `${PATH.REPORT_NO_ID}/${result.id}?type=${EOverviewType.DASHBOARD_OVERVIEW}`,
          '_blank',
        );
      }
    } catch (error) {
      console.error('Error on export overview', error);
    }
  };

  return (
    <TertiaryButton size="middle" data-testid="test-export-btn" onClick={onExport}>
      <div className="flex items-center gap-2">
        <div>{t('export.btn')}</div>
        <div>
          <FontAwesomeIcon icon={faFileExport} />
        </div>
      </div>
    </TertiaryButton>
  );
};
