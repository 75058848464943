import React from 'react';
import { Breadcrumb } from '@components/Breadcrumb';
import { PageLayout } from '@layouts';
import {
  Container,
  ListContainer,
  Inner,
  TopSection,
  TabKey,
  RichmenuCount,
  DeleteModal,
  EmptyState,
} from './style';
import {
  ERICHMENU_SETTING_TYPE,
  ERROR_DESCRIPTION_DEAULT_MESSAGE,
  ERROR_TITLE_DEAULT_MESSAGE,
  PATH,
  RICHMENU_ACTIVE_LIST_MENU,
  RICHMENU_BREADCRUMB_LABEL,
  RICHMENU_UPCOMING_LIST_MENU,
} from '@configs';
import { useLocation, Link } from 'react-router-dom';
import { LoadingWrap, LoadingCircle } from '@amityco/diana-bot';
import { Spin } from 'antd';
import { RichmenuChannelItem } from '@components/RichmenuChannelItem';
import { Tab } from '@components/Tab';
import {
  Font12G5W400,
  Font14G5W400,
  Font16G1W400,
  Font20G1W600,
} from '@components/UtilitiesComponent';
import { useTranslation } from '@hooks/useTranslation';
import { PrimaryButton } from '@pages/richmenu/Richmenu/RichmenuContent/style';
import { CustomAlertModal } from '@components/CustomAlertModal';
import { useRichmenuChannelContent } from './useRichmenuChannelContent';
import InfiniteScroll from 'react-infinite-scroll-component';

export const RichmenuChannelContent = () => {
  const {
    height,
    context,
    isLoading,
    isShowErrorModal,
    isFetchingActiveRichmenuList,
    isFetchingUpcomingRichmenuList,
    isShowDeleteRichmenu,
    isDeletingRichmenu,
    onFetchActiveList,
    onFetchUpcomingList,
    onFetchNextActiveList,
    onFetchNextUpcomingList,
    onIdle,
    onConfirmDelete,
    onDelete,
    onCancelDelete,
  } = useRichmenuChannelContent();

  const BREADCRUMB_CONFIG = {
    ...RICHMENU_BREADCRUMB_LABEL,
  } as { [key: string]: string };

  BREADCRUMB_CONFIG[context.channelId] = context.channelName;

  const t = useTranslation();
  const location = useLocation();
  const TAB_CONFIG = [
    {
      key: ERICHMENU_SETTING_TYPE.ACTIVE,
      tab: (
        <TabKey>
          {ERICHMENU_SETTING_TYPE.ACTIVE}
          {typeof context.count.activeRichmenu === 'number' && (
            <RichmenuCount>{context.count.activeRichmenu}</RichmenuCount>
          )}
        </TabKey>
      ),
      children: (
        <Spin
          spinning={isFetchingActiveRichmenuList}
          indicator={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
        >
          <ListContainer>
            {context.activeRichmenuList.items.length === 0 ? (
              <EmptyState>
                <Font14G5W400>
                  {t('richmenu.channel.list.no.active.richmenu.description')}
                </Font14G5W400>
              </EmptyState>
            ) : (
              <InfiniteScroll
                dataLength={context.activeRichmenuList.items.length}
                next={onFetchNextActiveList}
                hasMore={context.activeRichmenuList.hasMore}
                loader={<Spin indicator={<LoadingCircle />} />}
                height={height}
              >
                {context.activeRichmenuList.items.map((richmenu) => {
                  return (
                    <RichmenuChannelItem
                      key={richmenu.id}
                      id={richmenu.id}
                      dropdownMenu={RICHMENU_ACTIVE_LIST_MENU}
                      richmenuName={richmenu.richmenu.name}
                      imgUrl={richmenu.image}
                      menuBarLabel={richmenu.menuBarLabel}
                      menuBehavior={richmenu.menuBehavior}
                      targetAudience={richmenu.targetAudience}
                      priority={richmenu.priority}
                      dateToAdd={richmenu.dateToAddStr ?? '-'}
                      segment={richmenu.segment}
                      onConfirmDelete={() =>
                        onConfirmDelete(richmenu.id, ERICHMENU_SETTING_TYPE.ACTIVE)
                      }
                    />
                  );
                })}
              </InfiniteScroll>
            )}
          </ListContainer>
        </Spin>
      ),
    },
    {
      key: ERICHMENU_SETTING_TYPE.UPCOMING,
      tab: (
        <TabKey>
          {ERICHMENU_SETTING_TYPE.UPCOMING}
          {typeof context.count.upcomingRichmenu === 'number' && (
            <RichmenuCount>{context.count.upcomingRichmenu}</RichmenuCount>
          )}
        </TabKey>
      ),
      children: (
        <Spin
          spinning={isFetchingUpcomingRichmenuList}
          indicator={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
        >
          <ListContainer>
            {context.upcomingRichmenuList.items.length === 0 ? (
              <EmptyState>
                <Font14G5W400>
                  {t('richmenu.channel.list.no.upcoming.richmenu.description')}
                </Font14G5W400>
              </EmptyState>
            ) : (
              <InfiniteScroll
                dataLength={context.upcomingRichmenuList.items.length}
                next={onFetchNextUpcomingList}
                hasMore={context.upcomingRichmenuList.hasMore}
                loader={<LoadingCircle />}
                height={height}
              >
                {context.upcomingRichmenuList.items.map((richmenu) => {
                  return (
                    <RichmenuChannelItem
                      key={richmenu.id}
                      id={richmenu.id}
                      dropdownMenu={RICHMENU_UPCOMING_LIST_MENU}
                      richmenuName={richmenu.richmenu.name}
                      imgUrl={richmenu.image}
                      menuBarLabel={richmenu.menuBarLabel}
                      menuBehavior={richmenu.menuBehavior}
                      targetAudience={richmenu.targetAudience}
                      segment={richmenu.segment}
                      priority={richmenu.priority}
                      dateToAdd={richmenu.dateToAddStr ?? '-'}
                      onConfirmDelete={() =>
                        onConfirmDelete(richmenu.id, ERICHMENU_SETTING_TYPE.UPCOMING)
                      }
                    />
                  );
                })}
              </InfiniteScroll>
            )}
          </ListContainer>
        </Spin>
      ),
    },
  ];
  return (
    <PageLayout>
      <Spin
        spinning={isLoading}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <Breadcrumb
          breadcrumbLabel={BREADCRUMB_CONFIG}
          pathname={location.pathname}
          firstRoot={''}
        />
        <Container>
          <Inner>
            <TopSection>
              <div>
                <div data-testid="test-channel-name">
                  <Font20G1W600>{context.channelName}</Font20G1W600>
                </div>
                <Font12G5W400>{t('richmenu.channel.list.description')}</Font12G5W400>
              </div>
              <Link to={`${location.pathname}/editor`}>
                <PrimaryButton>
                  {t('richmenu.channel.list.add.richmenu.btn')}
                </PrimaryButton>
              </Link>
            </TopSection>
            <Tab
              defaultActiveKey={ERICHMENU_SETTING_TYPE.ACTIVE}
              tabs={TAB_CONFIG}
              onTabClick={(value: string) => {
                if (value === ERICHMENU_SETTING_TYPE.ACTIVE) onFetchActiveList();
                else onFetchUpcomingList();
              }}
            />
          </Inner>
        </Container>
        <CustomAlertModal
          title={ERROR_TITLE_DEAULT_MESSAGE}
          description={ERROR_DESCRIPTION_DEAULT_MESSAGE}
          visible={isShowErrorModal}
          onClick={onIdle}
          onClose={onIdle}
          btnTxt={t('general.ok.btn')}
        />
      </Spin>
      <DeleteModal
        data-testid="test-delete-modal"
        centered
        maskClosable={true}
        confirmBtnTxt={'Delete'}
        cancelBtnTxt={'Cancel'}
        isLoading={isDeletingRichmenu}
        handleClose={onCancelDelete}
        handleConfirm={onDelete}
        visible={isShowDeleteRichmenu}
      >
        <Font20G1W600>{t('richmenu.channel.list.delete.title')}</Font20G1W600>
        <Font16G1W400>
          {context.richmenuCannelToDelete.type === ERICHMENU_SETTING_TYPE.ACTIVE
            ? t('richmenu.channel.list.delete.active.description')
            : t('richmenu.channel.list.delete.upcoming.description')}
        </Font16G1W400>
      </DeleteModal>
    </PageLayout>
  );
};
