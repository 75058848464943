import { createContext } from 'react';

export type IPrompProviderContext = {
  showPrompt: () => Promise<void>;
  stayFunc?: React.MutableRefObject<{
    onSelectFirstPathInternal?: (path: string) => void;
    onSelectSecondPathInternal?: (path: string) => void;
  }>;
};

export const PromptContext = createContext<IPrompProviderContext>({
  showPrompt: () => Promise.resolve(),
});
