import { Result as AntdResult } from 'antd';
import styled from 'styled-components';

export const Container = styled.div``;

export const Result = styled(AntdResult)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-result-icon > svg {
    max-width: 32rem;
  }
`;
