import styled from 'styled-components/macro';
import amity_logo from '@assets/images/ams-logo.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 410px;
  height: 100vh;
  padding: 0 12px;
  margin: 0 auto;

  form {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
`;

export const Logo = styled.div`
  background-image: url(${amity_logo});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  height: 58px;
  margin-bottom: 40px;
`;

export const ActionWrap = styled.div`
  margin-top: 24px;
  button {
    width: 100%;
  }
`;

export const Version = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.palette?.GREY_5};
  text-align: center;
`;

export const ForgotPassword = styled.div`
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
  display: block;
  a {
    color: #9e9e9e !important;
  }
`;

export const AppVersion = styled.div`
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.18);
  font-weight: 400;
  font-size: 14px;
`;
