import { client, CIM_API_ENDPOINT } from '@api';
import { INetworkSettingBE, INetworkSettingRequest } from '@types';

export const fetchNetworkSettingByNid = async (nid: string) => {
  const { data }: { data: INetworkSettingBE } = await client.get(
    `${CIM_API_ENDPOINT}/api/v1/networks/${nid}`,
  );
  return data;
};

export const updateNetworkSetting = (data: INetworkSettingRequest) => {
  return client.post(`${CIM_API_ENDPOINT}/api/v1/networks`, data);
};
