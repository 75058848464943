import {
  IActiveUserChartData,
  IActiveUserChartResponse,
  ITotalMessageChartData,
  ITotalMessageChartResponse,
  IUserJourneyResponse,
  IUserJourneySankeyData,
} from '@types';

export enum EWorkerMessageType {
  TOTAL_MESSAGE_CHART = 'dashboard/total/message/chart',
  ACTIVE_USER_CHART = 'dashboard/active/user/chart',
  USER_JOURNEY_CHART = 'dashboard/user-journeys',
}

// Message Chart
export type ITotalMessageChartWorkerInput = {
  type: EWorkerMessageType.TOTAL_MESSAGE_CHART;
  payload: ITotalMessageChartResponse;
};

export type ITotalMessageChartWorkerOutput<T> = {
  type: EWorkerMessageType.TOTAL_MESSAGE_CHART;
  payload: T; //ITotalMessageChartData;
};

// User Chart
export type IActiveUserChartWorkerInput = {
  type: EWorkerMessageType.ACTIVE_USER_CHART;
  payload: IActiveUserChartResponse;
};

export type IActiveUserChartWorkerOutput<T> = {
  type: EWorkerMessageType.ACTIVE_USER_CHART;
  payload: T; //IActiveUserChartData;
};

// UserJourney Chart
export type IUserJourneyChartWorkerInput = {
  type: EWorkerMessageType.USER_JOURNEY_CHART;
  payload: IUserJourneyResponse;
};

export type IUserJourneyChartWorkerOutput<T> = {
  type: EWorkerMessageType.USER_JOURNEY_CHART;
  payload: T; //IUserJourneySankeyData[];
};

export type IWorkerMessageInputEvent = MessageEvent<
  | ITotalMessageChartWorkerInput
  | IActiveUserChartWorkerInput
  | IUserJourneyChartWorkerInput
>;
export type IWorkerMessageOutputEvent<T> = MessageEvent<
  | ITotalMessageChartWorkerOutput<T>
  | IActiveUserChartWorkerOutput<T>
  | IUserJourneyChartWorkerOutput<T>
>;
