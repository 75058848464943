import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
