import { IOption } from '@amityco/diana-bot';
import { IErrorMapper } from '@types';

export const MAPPER_API_MAX_TAKE = 10;
export const MAPPER_MAX_ENTITIES = 20;
export const MAPPER_SEARCH_INTENT_DEBOUNCE_TIME = 400;
export const MAPPER_CREATE_SUCCESS_MESSAGE = 'Mapper created successfully';
export const MAPPER_UPDATE_SUCCESS_MESSAGE = 'Mapper updated successfully';
export const MAPPER_DELETE_SUCCESS_MESSAGE = 'Mapper deleted successfully';
export const MAPPER_FLOW_MAX_TAKE = 500;
export enum EMapperActionType {
  TEMPLATE = 'template',
  CONVERSATION_FLOW = 'flow',
  OPEN_TICKET = 'assign_ticket',
}
export const MAPPER_ACTION_TYPE_OPTIONS: IOption[] = [
  {
    label: 'Template',
    value: EMapperActionType.TEMPLATE,
  },
  {
    label: 'Go to conversation flow',
    value: EMapperActionType.CONVERSATION_FLOW,
  },
  {
    label: 'Open ticket',
    value: EMapperActionType.OPEN_TICKET,
  },
];

export const enum EMapperActionTypeIndex {
  TEMPLATE = 0,
  CONVERSATION_FLOW,
}

export const MAPPER_ERROR_MAPPING: IErrorMapper = {
  'Unique constraint failed. (Mapper_duplicateNetworkIntentEntities_key)': {
    title: 'Mapper already existing',
    description: 'Let’s try new mapper',
  },
};
