import React from 'react';
import ErrorLoading from '@assets/images/error-loading.svg';
import { Result } from './style';
import { Button, GhostButton } from '@amityco/diana-bot';
import { useAuth, useMember } from '@providers';
import { useNavigate, useLocation } from 'react-router-dom';
import { ERROR_NO_PERMISSION, PATH } from '@configs';
import { useTranslation } from '@hooks';

export const Error = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { onLogout, onClearCognitoUser } = useAuth();
  const { cognitoUser } = useAuth();
  const { onClearMember } = useMember();
  const location = useLocation();
  const state = location.state as { message: string };
  const _renderHomeButton = () => {
    if (state?.message === ERROR_NO_PERMISSION) {
      return null;
    }
    return (
      <a href="/">
        <Button size="large">{t('error.page.back.to.home.btn')}</Button>
      </a>
    );
  };
  return (
    <Result
      icon={<img src={ErrorLoading} className="mx-auto" width={512} height={356} />}
      title={state?.message ? state.message : t('general.error.title')}
      extra={
        <>
          {_renderHomeButton()}
          {cognitoUser && (
            <GhostButton
              size="large"
              onClick={async () => {
                await onLogout();
                onClearCognitoUser();
                onClearMember();
                navigate(PATH.LOGIN);
              }}
            >
              {t('error.page.back.to.login.btn')}
            </GhostButton>
          )}
        </>
      }
    />
  );
};

export default Error;
