import React, { useState } from 'react';

import { Form } from 'antd';
import { ActionWrap, Container, ForgotPassword, Logo, AppVersion } from './style';

import {
  FormItem,
  TextInput,
  PasswordInput,
  PrimaryButton,
  LoadingIcon,
  DangerToast,
} from '@amityco/diana-bot';
import { ToastWrap } from '@components/UtilitiesComponent';

export type ILoginComponent = {
  errorMessage: string;
  loading: boolean;
  appVersion?: string;
  onForgotPassword: () => void;
  onLogin: (username: string, password: string) => void;
};

export const Login = ({
  errorMessage,
  loading,
  appVersion,
  onForgotPassword,
  onLogin,
}: ILoginComponent) => {
  return (
    <Container>
      <Logo />

      {errorMessage && (
        <ToastWrap>
          <DangerToast align="flex-start">{errorMessage}</DangerToast>
        </ToastWrap>
      )}
      <Form
        autoComplete="off"
        layout="vertical"
        onFinish={({ username, password }) => onLogin(username, password)}
      >
        <FormItem
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your username!',
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid username format',
            },
          ]}
        >
          <TextInput placeholder="Username" size="large" />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your password!',
            },
          ]}
        >
          <PasswordInput
            placeholder="Password"
            size="large"
            autoComplete="new-password"
          />
        </FormItem>
        <ActionWrap>
          <PrimaryButton
            size="large"
            htmlType="submit"
            disabled={loading}
            data-testid="test-login-btn"
          >
            {loading && (
              <LoadingIcon icon={['fas', 'sync-alt']} data-testid="test-loading-icon" />
            )}
            Log in
          </PrimaryButton>
          <ForgotPassword>
            <a onClick={onForgotPassword}>Forgot password?</a>
          </ForgotPassword>
        </ActionWrap>
      </Form>
      <AppVersion>{appVersion}</AppVersion>
    </Container>
  );
};
